import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { shortenaddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { getWnsDomain } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getChain, getChainLogo } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { Tooltip } from "@material-tailwind/react";


const Titles = ({ currentAddress, valid }) => {
    const [domain, setDomain] = useState("");
    const [toolTiptext, setToolTiptext] = useState("address");

    useEffect(() => {
        if (currentAddress != "" && valid) {
            getWnsDomain(currentAddress).then((result) => {
                setDomain(result);
            })
            setToolTiptext(currentAddress);
        }
    }, [currentAddress, valid]);

    async function copy() {
        await navigator.clipboard.writeText(currentAddress);
        setToolTiptext("Copied!");

        setTimeout(() => {
            setToolTiptext(currentAddress);
        }, 2000);

    }
    return (

        <div className="">

            <p className="font-semibold text-xl">Account Details</p>


            <div className="mt-6 pb-4 md:flex items-center justify-between ">
                <div className="flex items-center gap-x-5">
                    {/* <div className="bg-gray-400 w-16 h-16 flex justify-center items-center rounded-3xl">
                        <FontAwesomeIcon icon="user" className="text-white text-3xl" />
                    </div> */}
                    <div className="w-14 h-14 flex justify-center items-center rounded-2xl bg-gray-400">
                        <FontAwesomeIcon icon="user" className="text-white text-3xl" />
                    </div>

                    <div>
                        <p className="text-2xl font-bold" style={{}}>{domain != null && domain != "" && domain != "null" ? domain : "Account"}</p>
                        <Tooltip content={toolTiptext} placement="bottom" className="bg-gray-500 py-1 truncate px-4" >
                            <div className="flex items-center gap-x-1 mt-1" onClick={() => copy()}>
                                {/* <img src={getChainLogo()} className="w-4 h-4 rounded-full" /> */}
                                <p className="text-gray-500 text-md ">{shortenaddress(currentAddress)}</p>
                                <FontAwesomeIcon icon="copy" className="text-gray-500 text-xs" />
                            </div>
                        </Tooltip>
                    </div>



                </div>

                <div className="flex flex-col items-end">
                    <a className="hidden md:flex items-center gap-x-2 mt-0 bg-main px-4 py-2 rounded-full w-fit" href={"https://domains.w3.one/"} target={"_blank"}>
                        <p className="text-white text-sm">{domain != null && domain != "" && domain != "null" ? "Manage Username" : "Register Username"}</p>
                        <FontAwesomeIcon icon="arrow-right" className="text-white text-sm" />
                    </a>
                    {/* <a className="hidden md:flex items-center gap-x-2 mt-0 bg-main px-4 py-2 rounded-full w-fit mt-2" href={"https://opensea.io/" + currentAddress} target={"_blank"}>
                        <p className="text-white text-sm">View on Opensea</p>
                        <FontAwesomeIcon icon="arrow-right" className="text-white text-sm" />
                    </a> */}
                </div>
            </div>


        </div >
    );

}

export default Titles;

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}