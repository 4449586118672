import React, { useState, useContext, useEffect } from "react";
import TxList from "./partials/txList";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { colors } from "../../../../../../1.resources/1.css/colors";
import { TbCoins } from "react-icons/tb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Transactions = ({ currentAddress }) => {
    const [tabSelected, setTabSelected] = useState("normal");

    return (
        <div>
            <div className="flex items-center gap-x-10 mt-4 px-6 border-b-2 border-b-gray-200">
                <button onClick={() => setTabSelected("normal")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "normal" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                    <p className={`text-md ${tabSelected == "normal" ? "text-main" : "text-gray-500"}`}>Normal</p>
                </button>
                <button onClick={() => setTabSelected("internal")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "internal" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                    <p className={`text-md ${tabSelected == "internal" ? "text-main" : "text-gray-500"}`}>Internal</p>
                </button>
                <button onClick={() => setTabSelected("erc20")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "erc20" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                    <p className={`text-md ${tabSelected == "erc20" ? "text-main" : "text-gray-500"}`}>Erc-20</p>
                </button>
                <button onClick={() => setTabSelected("erc721")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "erc721" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                    <p className={`text-md ${tabSelected == "erc721" ? "text-main" : "text-gray-500"}`}>Erc-721</p>
                </button>
            </div >
            <TxList currentAddress={currentAddress} type={tabSelected} />
        </div>
    )

}

export default Transactions;


export const GetSkeletons = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} style={{ borderRadius: "0px" }} className="border-b border-b-gray-200">
                <SkeletonTheme baseColor={index % 2 == 0 ? "#fff" : "#fff"} highlightColor={index % 2 == 0 ? "#F3F4F6" : "#F3F4F6"} >
                    <Skeleton count={1} style={{
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        borderRadius: "0px"
                    }} />
                </SkeletonTheme>
            </div>
        )
    }
    );
    return Mapping;
}