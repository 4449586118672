import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Listbox, Transition } from '@headlessui/react'
import { allChains, getChain, getChainPath } from "../../../1.resources/2.js/0.global/0.smallfunctions/chain";

const HeaderRight = ({ }) => {
    const chains = allChains();
    const [selected, setSelected] = useState(chains[0])

    useEffect(() => {
        setSelected(chains[getChain().chainId - 1])
    }, [])

    function setChain(link) {
        window.location = window.location.pathname.replace(getChainPath(), "/" + link);
    }
    return (
        <div >
            <div className="w-[250px]">
                <Listbox value={selected} onChange={setSelected}>
                    <div className="relative">
                        <Listbox.Button className="relative w-full cursor-default rounded-xl bg-white px-4 py-2.5 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 border-2 border-gray-200">
                            <div className="flex items-center gap-x-2">
                                <img src={selected.img} className="w-6 h-6 rounded-full" />
                                <span className="block truncate font-semibold text-md">{selected.name}</span>
                            </div>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                                <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-black" style={{ fontSize: "100%" }} aria-hidden="true" />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options style={{ boxShadow: "0 0 0 2000px rgba(0, 0, 0, 0.25)" }} className="z-50 absolute mt-1 max-h-120 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {chains.map((chain, chainIdx) => (
                                    <Listbox.Option
                                        key={chainIdx}
                                        className={`py-3 px-4 text-black border-b border-b-gray-200 hover:bg-gray-100 cursor-pointer`}
                                        value={chain}
                                        onClick={() => setChain(chain.link)}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <div className="flex items-center gap-2">
                                                    <img src={chain.img} className="w-5 h-5 rounded-full" />
                                                    <span className={`block truncate font-semibold`}>{chain.name}</span>
                                                </div>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
        </div>
    );

}


export default HeaderRight;



