import React, { useState, useContext, useEffect } from "react";
import { getChain, getChainPath, getChainSymbol } from "../../../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { callW3Api, getErc20Logo, getErc721Metadata, getWnsDomain } from "../../../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { timeToString } from "../../../../../../../1.resources/2.js/0.global/0.smallfunctions/time";
import { Tooltip } from "@material-tailwind/react";
import { delay } from "../../../../../../../1.resources/2.js/0.global/0.smallfunctions/loading";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { normalizeFnName, shortenaddress } from "../../../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import WnsLogo from "../../../../../../0.global/logo/wnsLogo";
import { getNameTag } from "../../../../../../../1.resources/2.js/0.global/0.smallfunctions/getLabel";
import { BiCoin } from "react-icons/bi";
import EmptyImg from "../../../../../../../1.resources/3.files/images/empty.png";

const TxList = ({ currentAddress, type }) => {
    let limit = 25;
    const [txDetails, setTxDetails] = useState([]);
    const [pagesFetched, setPagesFetched] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [lastPage, setLastPage] = useState();
    const [cursor, setCursor] = useState("");
    const [detailsLoading, setDetailsLoading] = useState(true);

    async function fetchItems(param) {
        console.log("fetching");
        setLoading(true);
        setDetailsLoading(true);
        console.log("currentAddress", currentAddress);
        let result = await callW3Api("/tokens/transactions", { chain: getChain().chain, address: currentAddress, limit: limit, cursor: param == "change" ? "" : cursor, type: type });
        console.log(result);
        console.log("time", "")
        setDetailsLoading(false);


        if (param == "change") {
            setTxDetails([...result.items]);
            setCursor(result.cursor);
            setPagesFetched(1);
            setCurrentPage(1);
            setLoading(false);
            if (result.length < limit) {
                setLastPage(currentPage + 1);
            } else {
                setLastPage(null);
            }
        } else {
            setTxDetails([...txDetails, ...result.items]);
            setCursor(result.cursor);
            setPagesFetched(pagesFetched + 1);
            setCurrentPage(currentPage + 1);
            setLoading(false);
            if (result.length < limit) {
                setLastPage(currentPage + 1);
            }
        }
    }
    useEffect(() => {
        console.log("useEffect");
        if (currentAddress != "") {
            fetchItems("change");
        }
    }, [type, currentAddress])

    useEffect(() => {
        console.log("first");
    }, [])

    function pagination(param) {
        if (param == "next") {
            if (currentPage == pagesFetched) {
                fetchItems();
            } else {
                setCurrentPage(currentPage + 1);
            }
        } else if (param == "prev") {
            setCurrentPage(currentPage - 1);
        }
    }

    let css = {
        headingdiv: "truncate text-md text-gray-500 font-semibold",
        div: "truncate"
    }
    return (
        <div className="overflow-x-scroll ">
            {/* <div className="flex items-center pt-2 gap-2" style={{ display: detailsLoading ? "flex" : "none" }}>
                <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-gray-500" style={{ fontSize: "70%" }} spin />
                <p className="text-xs text-gray-500">Loading Details...</p>
            </div> */}
            {loading == false ?
                txDetails.length != 0 ? (
                    <div>
                        <div className="overflow-x-scroll">
                            {/* <TxLabels type={type} /> */}
                            <Labels />
                            <TxMapping items={txDetails.slice((currentPage - 1) * limit, (currentPage - 1) * limit + (limit + 1))} currentAddress={currentAddress} css={css} type={type} />
                        </div>
                        <div>
                            <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
                                <div>
                                    <p className="text-md text-gray-500">Showing <span className="">{25}</span> results</p>
                                </div>
                                <div>
                                    <p className="text-md text-gray-500">Page {currentPage}</p>
                                </div>
                                <div className="flex">
                                    <a href="#transactions" onClick={() => pagination("prev")} className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-md font-semibold text-gray-700 hover:bg-gray-50" style={{ display: currentPage == 1 ? "none" : "block" }}>Previous
                                    </a>
                                    <a href="#transactions" onClick={() => pagination("next")} className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-md font-semibold text-gray-700 hover:bg-gray-50" style={{ display: lastPage == null ? "block" : currentPage == lastPage ? "none" : "block" }}>Next
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center items-center">
                        <div>
                            <img src={EmptyImg} className="w-80" />
                            <div className="bg-white rounded-lg p-2 flex justify-center items-center my-2 pb-10">
                                <div>
                                    <p className="text-black pl-1 text-2xl font-semibold text-center">No transactions</p>
                                    <p className="text-gray-500 pl-1 text-lg text-center">There are no matching transactions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div>
                        <GetSkeletons paramsArray={[...Array(20).keys()]} />
                    </div>
                )
            }
        </div >
    );

}

export default TxList;

const Labels = () => {
    return (
        <div className="flex items-center border-b border-gray-200 py-4 px-14 w-[1200px] md:w-[1250px]">
            <div className="w-[40px] md:w-[55px] lg:w-[80px]">
                <p className="text-md text-gray-500 font-semibold"></p>
            </div>
            <div>
                <p className="text-md text-gray-500 font-semibold ml-[5px] md:ml-[5px] lg:ml-0">Transaction</p>
            </div>
            <p className="text-md text-gray-500 font-semibold ml-[90px] md:ml-[100px] lg:ml-[95px]">Method</p>
            <p className="text-md text-gray-500 font-semibold ml-[120px] md:ml-[130px] lg:ml-[130px]">Addresses</p>
            <p className="text-md text-gray-500 font-semibold ml-[350px] md:ml-[355px] lg:ml-[325px]">Value</p>
            <p className="text-md text-gray-500 font-semibold ml-[105px] md:ml-[105px] lg:ml-[100px]">Time</p>

        </div>
    )
}


const TxMapping = ({ items, currentAddress, css, type }) => {
    console.log(items)
    console.log(items.length)
    console.log(currentAddress);
    return (
        items.map((item, index) => (
            <a key={index} className="" href={getChainPath() + "/tx/" + item.transactionHash}>
                <div className={`px-5 md:px-6 lg:px-12 py-2 border-b border-b-gray-200 flex justify-between items-center hover:bg-gray-100 w-[1200px] md:w-[1250px]`} style={{ flexShrink: "0" }}>
                    <div className={`flex items-center gap-x-8 md:gap-x-10 w-9/12`} >


                        <GetDirection item={item} currentAddress={currentAddress} />
                        {type != "internal" ? (
                            <div className="w-2/12 pr-6">
                                <p className="text-md text-blue-500 truncate">{item.transactionHash}</p>
                            </div>
                        ) : (
                            null
                        )}

                        <div className="w-3/12">
                            <GetTitle item={item} type={type} />
                        </div>

                        <div className="flex items-center gap-x-4 mt-0 text-sm w-9/12" >
                            <div className="flex items-center gap-x-2 bg-gray-50 rounded-xl px-4 py-1.5 border border-gray-200 w-fit">
                                <p className="truncate text-md text-gray-500">From:</p>
                                <GetDomain param={"from"} item={item} currentAddress={currentAddress} />
                            </div>

                            <div className="flex items-center gap-x-2 bg-gray-50 rounded-xl px-4 py-1.5 border border-gray-200 w-fit mt-0 md:mt-0">
                                <p className="truncate text-md text-gray-500">To:</p>

                                <GetDomain param={"to"} item={item} currentAddress={currentAddress} />
                            </div>
                        </div>

                    </div>
                    <div className="w-3/12 flex items-center justify-between md:justify-between gap-x-8 mt-0 rounded-xl px-0 py-2">
                        <div className="flex items-center justify-between w-full">
                            <div className="w-7/12 truncate">
                                {/* <p className="md:hidden text-md font-semibold truncate">Value</p> */}
                                {type == "erc20" ? (
                                    <p className="whitespace-nowrap text-md md:text-start truncate">{(parseFloat((parseFloat((item?.tokenBalance))).toFixed(3))).toLocaleString() + " " + item?.tokenSymbol?.substring(0, 5)}</p>
                                ) : type == "erc721" ? (
                                    <p className="whitespace-nowrap text-md md:text-start truncate">#{item?.tokenID?.substring(0, 10)}</p>
                                ) : (
                                    <p className="whitespace-nowrap text-md md:text-start truncate">{item?.value > 1 ? parseFloat((parseFloat(item?.value)).toFixed(2)).toLocaleString() : parseFloat((parseFloat(item?.value)).toFixed(4)).toLocaleString()}</p>
                                )}
                            </div>
                            <div className="block w-5/12">
                                <Tooltip content={(new Date(item.timeStamp)).toLocaleString()} placement="top" className="bg-gray-500 text-xs py-1" >
                                    <p className="whitespace-nowrap text-md text-gray-500 md:text-start">{timeToString(new Date(item.timestamp).getTime())}</p>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="text-black text-md" />
                        </div>
                    </div>
                </div>
            </a >

        ))
    )
}

export const GetSkeletons = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} style={{ borderRadius: "0px" }} className="border-b border-b-gray-200">
                <SkeletonTheme baseColor={index % 2 == 0 ? "#fff" : "#fff"} highlightColor={index % 2 == 0 ? "#F3F4F6" : "#F3F4F6"} >
                    <Skeleton count={1} style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        borderRadius: "0px"
                    }} />
                </SkeletonTheme>
            </div>
        )
    }
    );
    return Mapping;
}

const GetTitle = ({ item, type }) => {

    return (
        type == "normal" ? (
            <p className="truncate text-md font-semibold">{item.method.length > 15 ? item.method.substring(0, 15) + "..." : item.method}</p>
        ) : type == "internal" ? (
            <div className="flex items-center gap-x-2">
                <p className="truncate text-md font-semibold">Parent Tx: </p>
                <a href={getChainPath() + "/tx/" + item.transactionHash} className="truncate text-lg text-blue-500">{shortenaddress(item.transactionHash)}</a>
            </div>
        ) : type == "erc20" ? (
            <div className="flex items-center gap-x-4 mb-4">
                <ErcLogo item={item} type={type} />
                <div>
                    <p className="truncate text-md font-semibold">{item?.tokenSymbol?.substring(0, 10)}</p>
                    <p className="truncate text-md text-gray-500">{(item?.tokenName?.substring(0, 15))}</p>
                </div>
            </div>
        ) : type == "erc721" ? (
            <div className="flex items-center gap-x-4 mb-4">
                <ErcLogo item={item} type={type} />

                <div>
                    <p className="truncate text-md font-semibold">{item?.tokenName?.substring(0, 10)}</p>
                    <p className="truncate text-md text-gray-500">{(item?.tokenSymbol?.substring(0, 10))}</p>
                </div>
            </div>
        ) : (
            <p className="truncate text-md font-semibold">{item.method}</p>
        )

    )
}

const ErcLogo = ({ item, type }) => {
    const [logo, setLogo] = useState("");

    async function init() {
        console.log(item);
        if (type == "erc20") {
            let result = await getErc20Logo(item?.contractAddress);
            console.log(result);
            if (result != "null" && result != null) {
                setLogo(result);
            }
        } else {
            let result = await getErc721Metadata(item?.contractAddress, item?.tokenID);
            console.log(result);
            if (result?.normalized_metadata?.image != null) {
                result?.normalized_metadata?.image.includes("ipfs://") ? setLogo("https://ipfs.io/ipfs/" + result?.normalized_metadata?.image.substring(7)) : setLogo(result?.normalized_metadata?.image);
            }

        }
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <div>
            {logo != "" && logo != null ? (
                type == "erc20" ? (
                    <div className="w-10 h-10 flex justify-center items-center rounded-2xl">
                        <img src={logo} className="w-10 h-10 rounded-2xl" onError={(e) => { e.target.src = ""; setLogo("") }} />
                    </div>
                ) : (
                    <div className="w-14 h-14 flex justify-center items-center rounded-2xl">
                        <img src={logo} className="w-14 h-14 rounded-2xl" onError={(e) => { e.target.src = ""; setLogo("") }} />
                    </div>
                )
            ) : (
                type == "erc20" ? (
                    <div className="bg-gray-400 w-10 h-10 flex justify-center items-center rounded-3xl">
                        <BiCoin className="text-white text-lg" />
                    </div>
                ) : (
                    <div className="bg-gray-400 w-14 h-14 flex justify-center items-center rounded-3xl">
                        <FontAwesomeIcon icon={"image"} className="text-white text-xl" />
                    </div>
                )
            )}
        </div>
    )
}

const GetDirection = ({ item, currentAddress }) => {

    return (
        <div className={`${item.status == "0" ? "bg-red-100" : item?.from?.toLowerCase() == currentAddress.toLowerCase() == item?.to?.toLowerCase() ? "bg-gray-200" : item?.from?.toLowerCase() == currentAddress.toLowerCase() ? "bg-blue-100" : "bg-green-100"} rounded-lg p-2 h-fit flex justify-center items-center min-w-[50px]`}>
            <p className={`${item.status == "0" ? "text-red-500" : item?.from?.toLowerCase() == currentAddress.toLowerCase() == item?.to?.toLowerCase() ? "text-gray-500" : item?.from?.toLowerCase() == currentAddress.toLowerCase() ? "text-blue-500" : "text-green-500"} text-xs`}>
                {item.status == "0" ? "FAILED" : item.from.toLowerCase() == currentAddress.toLowerCase() == item?.to?.toLowerCase() ? "SELF" : item.from.toLowerCase() == currentAddress.toLowerCase() ? "OUT" : "IN"}
            </p>
        </div>
    )
}

export const GetDomain = ({ param, item, currentAddress, css, size, weight }) => {
    const [tag, setTag] = useState(null);
    const [domain, setDomain] = useState(null);
    const [address, setAddress] = useState(null);

    useEffect(() => {
        if (address != null && currentAddress != null) {
            getNameTag(address).then((nametag) => {
                if (nametag != "null") {

                    var doc = new DOMParser().parseFromString(nametag, "text/xml");

                    let name;
                    try {
                        name = doc.getElementsByTagName("span")[0].textContent;
                    } catch (e) {
                        name = nametag;
                    }

                    console.log(name);
                    setTag(name.length > 16 ? name.substring(0, 16) + "..." : name);
                } else {
                    getWnsDomain(address).then((domain) => {
                        if (domain != "null") {
                            setDomain(domain);
                        }
                    })
                }
            });

        }
    }, [address])

    useEffect(() => {
        let queryAddress;
        if (param == "from") {
            queryAddress = item?.from;
        } else {
            queryAddress = item?.to;
        }
        setAddress(queryAddress);
    }, [])

    return (
        <div className={size == null ? `truncate text-md` : size == "lg" ? "truncate text-lg" : size == "xl" ? "truncate text-xl" : size == "sm" ? "truncate text-sm" : "truncate text-md"} >
            <div className={weight == null ? "" : "font-semibold"}>
                <Tooltip content={address} placement="top" className="bg-gray-500 py-1 truncate px-4" >
                    {address?.toLowerCase() == currentAddress?.toLowerCase() ? (
                        tag != null ? (
                            <div className="flex gap-2 items-center">
                                <FontAwesomeIcon icon={['far', 'fa-file-code']} className="text-black" style={{ fontSize: "100%" }} />
                                <p className="truncate">{tag.length > 15 ? tag.substring(0, 15) + "..." : tag}</p>
                            </div>

                        ) : (
                            domain != null ? (
                                <div className="flex items-center gap-2">
                                    <WnsLogo color={"#000"} size={size == null ? "14px" : size == "lg" ? "16px" : size == "xl" ? "20px" : "14px"} />
                                    <p className="truncate">{domain}</p>
                                </div>
                            ) : (
                                <p className="truncate">{address == null ? "" : shortenaddress(address)}</p>
                            )
                        )
                    ) : (
                        tag != null ? (
                            <div className="flex gap-2 items-center">
                                <FontAwesomeIcon icon={['far', 'fa-file-code']} className="text-link" style={{ fontSize: "90%" }} />
                                <a href={getChainPath() + "/address/" + address} className={"text-link truncate"}>{tag.length > 15 ? tag.substring(0, 15) + "..." : tag}</a>
                            </div>

                        ) : (
                            domain != null ? (
                                <div className="flex items-center gap-2">
                                    <WnsLogo color={"#0171E2"} size={size == null ? "14px" : size == "lg" ? "16px" : size == "xl" ? "20px" : "14px"} />
                                    <a href={getChainPath() + "/address/" + address} className={"text-link truncate"}>{domain}</a>
                                </div>
                            ) : (
                                <a href={getChainPath() + "/address/" + address} className={"text-link truncate"}>{address == null ? "" : shortenaddress(address)}</a>
                            )
                        )
                    )}
                </Tooltip>
            </div>
        </div>
    )
}