import React, { useState, useEffect, useContext } from "react";
import Footer from "./2.views/0.global/footer/footer";
import Header from "./2.views/0.global/header/header";
import Home from "./2.views/1.home/home";
import Txs from "./2.views/2.txs/txs";
import Address from "./2.views/3.address/address";
import Top from "./2.views/4.top/top";
import Token from "./2.views/5.token/token";

const Main = ({ }) => {

    const [path, setPath] = useState();

    useEffect(() => {
        //init();
        let url = window.location.pathname;
        if (url == "/") {
            window.location.href = "/eth/";
        }
        let array = url.split("/");
        console.log(array);
        if (array[2] == "") {
            setPath(0);
        } else if (array[2] == "tx" || array[3] == "tx") {
            setPath(1);
        } else if (array[2] == "address" || array[3] == "address") {
            setPath(2);
        } else if (array[2] == "token" || array[3] == "token") {
            setPath(4);
        } else if (array[1] == "top") {
            setPath(3);
        } else {
            setPath(0);
        }
    }, []);

    return (
        <div id="top" className="overflow-x-clip">
            {path != null ? (

                <div className="">
                    <div style={{ zIndex: 10000000000000000000000000 }} className="">
                        <Header />
                    </div>
                    <div style={{ zIndex: -10000000000 }} className="bg-gray-100">
                        {path == 0 ? (
                            <Home />
                        ) : path == 1 ? (
                            <Txs />
                        ) : path == 2 ? (
                            <Address />
                        ) : path == 3 ? (
                            <Top />
                        ) : path == 4 ? (
                            <Token />
                        ) : (<div />)
                        }
                    </div>

                    < Footer />



                </div >
            ) : (<div />)}
        </div>
    )
}

export default Main;


