import React from 'react';
import Logo from "../../../1.resources/3.files/logo/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {

    return (
        <div className='bg-[#1D1D1F]'>
            <div>
                <div id="mainhead" className='px-10 md:px-40 lg:px-60 mx-0 lg:mx-24 pt-20' >
                    <div className='flex justify-left items-left text-left md:text-left'>
                        <div>
                            <div className='flex justify-start md:justify-start items-center gap-x-2 md:gap-x-3 mb-8'>
                                <img src={Logo} alt="logo" className="w-14 md:w-14 mb-0" />
                                {/* <p className='text-white text-2xl md:text-2xl font-bold'>W3 <span className='text-gray-500'>Chat</span></p> */}
                            </div>
                            <p className='text-white text-2xl md:text-4xl font-bold'>
                                W3 Explorer is part of the W3 ecosystem</p>

                            <div className='mt-4'>
                                <p className='text-gray-400 text-xl'>
                                    W3 is helping build the third generation of Web.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-20' >
                <div className='px-10 md:px-40 lg:px-60 mx-0 lg:mx-24 flex' >
                    <div className='flex md:flex w-full'>
                        <div className='w-6/12'>
                            <div>
                                <div style={{}} className="">
                                    {/* <div style={{}}>
                                        <p className="text-xl pb-2" style={{ color: "white", fontWeight: "bold" }}>Categories</p>
                                        <a className="graylink" href="/category/premium">
                                            <p className="text-white py-0.5">Premium</p>
                                        </a>
                                        <a className="graylink" href="/category/dictionary">
                                            <p className="text-white py-0.5">Dictionary</p>
                                        </a>
                                        <a className="graylink" href="/category/numbers">
                                            <p className="text-white py-0.5">Numbers</p>
                                        </a>
                                        <a className="graylink" href="/category/names">
                                            <p className="text-white py-0.5">Names</p>
                                        </a>
                                        <a className="graylink" href="/category/countries">
                                            <p className="text-white py-0.5">Countries</p>
                                        </a>
                                        <a className="graylink" href="/category/cities">
                                            <p className="text-white py-0.5">Cities</p>
                                        </a>
                                    </div>
                                    <div style={{}}>
                                        <p className="text-xl pb-2" style={{ color: "white", fontWeight: "bold" }}>Marketplace</p>
                                        <a className="graylink" href="/marketplace/domains">
                                            <p className="text-white py-0.5">Domains</p>
                                        </a>
                                        <a className="graylink" href="/marketplace/membership">
                                            <p className="text-white py-0.5">Membership</p>
                                        </a>
                                    </div>
                                    <div id="links" style={{}}>
                                        <p className="text-xl pb-2" style={{ color: "white", fontWeight: "bold" }}>Account</p>
                                        <a className="graylink" href="/account/mydomains">
                                            <p className="text-white py-0.5">My Domains</p>
                                        </a>
                                        <a className="graylink" href="/account/manage">
                                            <p className="text-white py-0.5">Manage</p>
                                        </a>
                                        <a className="graylink" href="/account/wishlist">
                                            <p className="text-white py-0.5">Wishlist</p>
                                        </a>
                                    </div> */}
                                    <div style={{}}>
                                        <p className="text-xl pb-2" style={{ color: "white", fontWeight: "bold" }}>W3 Ecosystem</p>
                                        <a className="graylink" href="https://w3.one">
                                            <p className="text-gray-400 py-0.5 text-lg">W3 Labs</p>
                                        </a>
                                        <a className="graylink" href="https://domains.w3.one">
                                            <p className="text-gray-400 py-0.5 text-lg" >W3 Name Service</p>
                                        </a>
                                        <a className="graylink" href="https://academy.w3.one">
                                            <p className="text-gray-400 py-0.5 text-lg">W3 Academy</p>
                                        </a>
                                        <a className="graylink" href="https://mail.w3.one">
                                            <p className="text-gray-400 py-0.5 text-lg">W3 Mail</p>
                                        </a>
                                        <a className="graylink" href="https://wallet.w3.one">
                                            <p className="text-gray-400 py-0.5 text-lg">W3 Wallet</p>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <br />

                        </div>
                        <div className='w-6/12'>
                            <div>
                                <div style={{}}>
                                    <p className='text-white font-bold text-lg'>Join the community</p>
                                    <div className="flex mt-4">
                                        <a className='flex justify-center items-center bg-white cursor pointer flex justify-center items-center w-12 h-12' style={{ borderRadius: "18px" }} href="https://twitter.com/wnsdomains" target="_blank">
                                            <FontAwesomeIcon icon={["fab", "fa-twitter"]} className="bg-white text-black" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="desktopheader" style={{ paddingTop: "2.5%" }}>
                    <div className="px-10 md:px-40 lg:px-60 mx-0 lg:mx-24 mt-4 pb-10 flex justify-between items-center">
                        <div>
                            <a className="graylink gap-x-1" href="/" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                <img src={Logo} alt="Logo" style={{ width: "24px" }} />
                                <p style={{ color: "#fff", marginLeft: '5%', fontSize: "130%", fontWeight: "bold", whiteSpace: "nowrap" }}>W3 <span className='text-gray-500 font-semibold'>Explorer</span></p>
                            </a>
                        </div>
                        <div className='text-right'>
                            <p className='text-gray-400' >contact@w3.one</p>
                            <p className='text-gray-400 text-[12px]'>© W3 Labs, 2021</p>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default Footer;
