import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { shortenaddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { callEthplorerApi, callMoralisApi, callW3Api, getErc20Stats, getWnsDomain } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getChainSymbol } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { GetDomain } from "../../../../3.address/components/lower/partials/transactions/partials/txList";
import { Variables } from "../../../../../1.resources/2.js/0.global/2.contracts/variables";
import { getCloudProvider } from "../../../../../1.resources/2.js/0.global/2.contracts/cloudProvider";
import { formatinusd } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
const { ethers } = require("ethers");


const Glance = ({ currentAddress, details }) => {
    const [totalSupply, setTotalSupply] = useState(0);
    const [price, setPrice] = useState(0);
    const [marketCap, setMarketCap] = useState(0);
    const [holders, setHolders] = useState(0);
    const [transfers, setTransfers] = useState(0);
    let css = {
        req: "font-semibold text-lg",
        res: "text-sm text-gray-500 mt-1",
        div: ""
    }

    const countZeroes = (value) => {
        //calculate number of zeroes after decimal point until the first number
        let count = 0;
        let str = value.toString();
        for (let i = str.indexOf(".") + 1; i < str.length; i++) {
            if (str[i] == "0") {
                count++;
            } else {
                break;
            }
        }
        return count;
    }

    const afterZeros = (value) => {
        //get the first three non-zero numbers after decimal point
        let str = value.toString();
        console.log(str);
        let count = 0;
        let result = "";
        for (let i = str.indexOf(".") + 1; i < str.length; i++) {
            if (str[i] != "0") {
                result += str[i];
                count++;
            }
            if (count == 3) {
                break;
            }
        }
        console.log(result);
        return result;

    }

    async function init() {
        let result2 = await callW3Api("/tokens/details/ethplorer", { address: currentAddress });
        console.log(result2);

        setTotalSupply(result2.totalSupply / 10 ** result2.decimals);
        setPrice(result2.price ? result2?.price?.rate : 0);
        setMarketCap(result2.price ? result2?.price?.rate?.toFixed(20) * (result2.totalSupply / 10 ** result2.decimals) : 0);
        setHolders(result2.holdersCount);
        setTransfers(result2.transfersCount);
    }

    useEffect(() => {
        if (currentAddress != "") {
            init();
        }
    }, [currentAddress])

    return (
        <div className="">
            {/* <div className="flex justify-between items-center px-0 py-2 rounded-xl">
                <div className="flex items-center gap-x-4">
                    <div className="bg-gray-400 w-12 h-12 flex justify-center items-center rounded-2xl">
                        <FontAwesomeIcon icon="user" className="text-white text-xl" />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-main" style={{}}>{"adam.web3"}</p>
                        <p className="text-gray-500 text-md">{shortenaddress(txDetails.from_address)}</p>
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon icon="arrow-right" className="text-gray-500 text-xl" />
                </div>
                <div className="flex items-center gap-x-4">
                    <div className="bg-gray-400 w-12 h-12 flex justify-center items-center rounded-2xl">
                        <FontAwesomeIcon icon="user" className="text-white text-xl" />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-main" style={{}}>{"george.web3"}</p>
                        <p className="text-gray-500 text-md">{shortenaddress(txDetails.from_address)}</p>
                    </div>
                </div>

            </div> */}

            {details != null && details != "" && details != "null" ? (
                <div className="mt-4 mb-8">
                    <div className="grid grid-cols-2 lg:grid-cols-5 gap-y-6 rounded-2xl text-start ">
                        {/* <div >
                            <p className={css.res}  >From</p>
                            <GetDomain param={"from"} item={txDetails} currentAddress={""} size={window.innerWidth < 767 ? "lg" : "lg"} weight={"semibold"} />

                        </div>
                        <div >
                            <p className={css.res}>To</p>
                            <GetDomain param={"to"} item={txDetails} currentAddress={""} size={window.innerWidth < 767 ? "lg" : "lg"} weight={"semibold"} />

                        </div> */}
                        <div >
                            <p className={css.res}>Price</p>
                            {price > 1 ? (
                                <p className={css.req} >{formatinusd(price)}</p>
                            ) : (
                                countZeroes(price.toFixed(20)) > 3 ? (
                                    <p className={css.req}>$0.0<sub>{countZeroes(price.toFixed(20))}</sub>{afterZeros(price.toFixed(20))}</p>
                                ) : (
                                    <p className={css.req}>${price.toFixed(5)}</p>

                                )
                            )}
                        </div>




                        <div >
                            <p className={css.res}>Holders</p>
                            <p className={css.req} >{holders.toLocaleString()}</p>
                        </div>

                        <div >
                            <p className={css.res}>Transfers</p>
                            <p className={css.req} >{transfers.toLocaleString()}</p>
                        </div>
                        <div >
                            <p className={css.res}>Market Cap</p>
                            <p className={css.req} >{formatinusd(marketCap)}</p>
                        </div>
                        <div >
                            <p className={css.res}>Total Supply</p>
                            <p className={css.req} >{totalSupply.toLocaleString()}</p>
                        </div>

                    </div>
                </div>
            ) : (null)}
        </div >
    );

}

export default Glance;
