import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Upper from "./components/upper/upper";
import Lower from "./components/lower/lower";
import { ethers } from "ethers";

const Address = ({ }) => {
    const [tabSelected, setTabSelected] = useState("transactions");
    const [totalErc20, setTotalErc20] = useState(0);
    const [totalNfts, setTotalNfts] = useState(0);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        document.getElementById("title").innerHTML = "Address Details - W3 Explorer";
        if (ethers.utils.isAddress(window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1)) == true) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [])


    return (

        <div className="bg-white">
            <Upper tabSelected={tabSelected} setTabSelected={setTabSelected} totalErc20={totalErc20} totalNfts={totalNfts} valid={valid} />
            <Lower tabSelected={tabSelected} setTabSelected={setTabSelected} setTotalErc20={setTotalErc20} setTotalNfts={setTotalNfts} valid={valid} />
        </div >
    );

}

export default Address;
