import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from "@material-tailwind/react";
import { callW3Api } from "../../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getChain } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { ethers } from "ethers";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { colors } from "../../../../../../1.resources/1.css/colors";
import Empty_Nft from "../../../../../../1.resources/3.files/images/empty_nft.png";

const NFTs = ({ currentAddress, setTotalNfts }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);

    async function init() {
        setLoading(true);
        let result = await callW3Api("/address/erc721/get", { chain: getChain().chain, address: window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1), limit: 10 });
        console.log(result);
        result.ownedNfts.map((item) => {
            console.log(item.contractMetadata.openSea.floorPrice);
            item["floor"] = isNaN(parseFloat(item.contractMetadata.openSea.floorPrice)) ? 0 : parseFloat(item.contractMetadata.openSea.floorPrice);
        })
        setTotalNfts(result.totalCount);
        let final = [...result.ownedNfts]
        let sorted = final.slice().sort((a, b) => b.floor - a.floor);

        setItems(sorted);
        setTotal(result.totalCount);
        setLoading(false);
    }

    useEffect(() => {
        init();
    }, [])


    return (
        <div>

            <div className="">
                {loading ? (
                    <div className="grid grid-cols-4 gap-10 py-2">
                        <GetSkeletons paramsArray={[...Array(4).keys()]} />
                    </div>
                ) : (
                    items.length != 0 ? (
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 py-2 px-5 md:px-0">
                            <GetMapping items={items} />
                        </div>
                    ) : (
                        <div className="flex justify-center items-center">
                            <div className="bg-white rounded-lg p-2 w-fit h-fit flex items-center my-10">
                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} className="text-gray-500" style={{ fontSize: "90%" }} />
                                <p className="text-gray-500 pl-1 text-sm">There are no verified collections</p>
                            </div>
                        </div>
                    )
                )}
            </div>

        </div>
    );

}

export default NFTs;


const GetMapping = ({ items }) => {
    const [imgLoaded, setImgLoaded] = useState(false);
    return (
        items.map((item, index) => (
            <div>
                <div className={"hover:-translate-y-2 bg-white border-2 border-gray-200 cursor-pointer rounded-2xl"} >
                    <a target="_blank" href={`https://opensea.io/assets/ethereum/${item.contract.address}/${parseInt(item.id.tokenId)}`}>
                        <div>
                            <div style={{ display: imgLoaded ? "none" : "block", borderRadius: "18px" }} className={"p-2"}>
                                <SkeletonTheme baseColor={colors.gray100} highlightColor={colors.gray200} >
                                    <Skeleton count={1} style={{
                                        width: "100%",
                                        paddingTop: "100%",
                                        borderRadius: "18px"
                                    }} />
                                </SkeletonTheme>
                            </div>
                            <img src={item.media[0].gateway}
                                style={{ borderRadius: "20px", display: imgLoaded ? "block" : "none" }}
                                onLoad={(e) => { setImgLoaded(true) }} onError={(e) => { e.target.src = Empty_Nft }} className="p-2 aspect-square object-cover" />

                            <div className="px-4 pb-4 pt-3">
                                <p className="text-md truncate text-gray-500">{item.contractMetadata.openSea.collectionName}</p>
                                <p className="font-semibold text-lg truncate">{item.title != null && item.title != "" ? item.title : "#" + parseInt(item.id.tokenId)}</p>
                            </div>
                        </div>
                        <div style={{}} className="border-t-2 border-t-gray-100 bg-white rounded-b-2xl px-4">
                            <div style={{}} className="py-3 flex items-center gap-x-2 justify-between">
                                <p className="text-main">View on Opensea</p>
                                <FontAwesomeIcon icon={['fas', 'fa-arrow-right']} className="text-main text-sm" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        ))
    )
}


const GetSkeletons = ({ paramsArray }) => {
    const Mapping = paramsArray.map((item, index) => {
        return (
            <div key={index} style={{ borderRadius: "0px" }} className="border-b border-b-gray-200 rounded-lg">
                <SkeletonTheme baseColor={index % 2 == 0 ? "#fff" : "#F3F4F6"} highlightColor={index % 2 == 0 ? "#F3F4F6" : "#e9e9e9"} >
                    <Skeleton count={1} style={{
                        paddingTop: "60px",
                        paddingBottom: "60px",
                        borderRadius: "0px"
                    }} />
                </SkeletonTheme>
            </div>
        )
    }
    );
    return Mapping;
}