import React, { useState, useContext, useEffect } from "react";
import { getChain, getChainPath, getChainSymbol } from "../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { callW3Api } from "../../1.resources/2.js/0.global/3.api/callW3Api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { timeToString } from "../../1.resources/2.js/0.global/0.smallfunctions/time";
import { Tooltip } from "@material-tailwind/react";
import { delay } from "../../1.resources/2.js/0.global/0.smallfunctions/loading";
import { shortenaddress } from "../../1.resources/2.js/0.global/0.smallfunctions/global";
import Upper from "./components/upper/upper";
import Lower from "./components/lower/lower";
import { GetSkeletons } from "../3.address/components/lower/partials/transactions/transactions";
import EmptyImg from "../../1.resources/3.files/images/empty.png";

const Txs = ({ }) => {
    const [tabSelected, setTabSelected] = useState("details");
    const [txDetails, setTxDetails] = useState();
    const [copyp, setCopyp] = useState("Copy");
    const [loading, setLoading] = useState(true);

    async function init() {
        setLoading(true);
        let result = await callW3Api("/tx/get", { chain: getChain().chain, hash: window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1) });
        console.log(result);
        setTxDetails(result[0]);
        setLoading(false);
    }
    useEffect(() => {
        document.getElementById("title").innerHTML = "Transaction Details - W3 Explorer";
        init();
    }, [])

    async function copyClipboard(value) {
        console.log("copying.,.")
        navigator.clipboard.writeText(value);
        setCopyp("Copied succesfully!");
        await delay(2000);
        setCopyp("Copy");
    }

    return (
        <div>
            <div>
                <Upper tabSelected={tabSelected} setTabSelected={setTabSelected} txDetails={txDetails} copyp={copyp} setCopyp={setCopyp} copyClipboard={copyClipboard} />
            </div>

            <div className="bg-gray-100 flex justify-center md:py-10 ">
                <div className="w-full lg:w-[1400px] px-0 md:px-10 lg:px-20 ">
                    <div className="py-5 md:rounded-3xl bg-white border-2 border-gray-200 w-full">
                        <div>
                            {loading ? (
                                <GetSkeletons paramsArray={[...Array(8).keys()]} />
                            ) : (
                                txDetails != null ? (
                                    <Lower item={txDetails} copyp={copyp} setCopyp={setCopyp} copyClipboard={copyClipboard} />
                                ) : (
                                    <div className="flex justify-center items-center">
                                        <div className="flex flex-col items-center">
                                            <img src={EmptyImg} className="w-80" />
                                            <div className="bg-white rounded-lg p-2 flex justify-center items-center my-2 pb-10">
                                                <div>
                                                    <p className="text-black text-2xl font-semibold text-center">Oops!</p>
                                                    <p className="text-gray-500 text-lg text-center px-80 text-center">We don't have information about that transaction yet. Check back later!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div >
            </div>
        </div>
    );

}

export default Txs;



