import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { shortenaddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { callW3Api, getWnsAddress } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { formatinusd, tokenToUsd } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { getChain, getChainPath, getChainSymbol } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import WnsLogo from "../../../../0.global/logo/wnsLogo";

const Glance = ({ currentAddress, totalErc20, totalNfts, valid }) => {
    const [copyp, setCopyp] = useState("Copy");
    const [balance, setBalance] = useState("0");
    const [balanceUSD, setBalanceUSD] = useState("0");
    const [domain, setDomain] = useState("");
    const [contract, setContract] = useState("");

    async function getBalance() {
        let result = await callW3Api("/address/balance/get", { chain: getChain().chain, address: currentAddress });
        console.log(result);
        setBalance(result.balance / 1e18);
        setBalanceUSD(await tokenToUsd(parseFloat(result.balance / 1e18), getChainSymbol()));
    }

    async function getDomain() {
        let domain = await getWnsAddress(currentAddress);
        setDomain(domain);
    }

    async function getContract() {
        // let result = await callW3Api("/address/contract/details", { chain: getChain().chain, address: window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1) });
        // console.log(result);
        // if (result[0].symbol != "") {
        //     setContract({
        //         name: result[0].name,
        //         symbol: result[0].symbol,
        //         logo: result[0].logo
        //     })
        // } else if (result[1].symbol != "") {
        //     setContract({
        //         name: result[1].name,
        //         symbol: result[1].symbol,
        //         logo: result[1].logo
        //     })
        // }
    }
    useEffect(() => {
        if (currentAddress != "" && valid) {
            getBalance();
            getDomain();
            getContract();
        }
    }, [currentAddress, valid])

    let css = {
        req: "font-semibold text-lg",
        res: "text-sm text-gray-500 mt-1",
        div: ""
    }

    return (

        <div className="rounded-2xl">
            <div className="mt-4 mb-10">
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-10 rounded-2xl text-start ">
                    <div className={css.div}>
                        <p className={css.res}  >ETH Balance</p>
                        <p className={css.req} >{parseFloat(balance).toFixed(3)} {getChainSymbol()}</p>
                    </div>
                    <div className={css.div}>
                        <p className={css.res}>ETH Value</p>
                        <p className={css.req} >{balanceUSD}</p>
                    </div>
                    <div className={css.div}>
                        <p className={css.res}>ERC20 Balance</p>
                        <div style={{ display: "flex" }} className="flex items-center">
                            <p className={css.req} >{formatinusd(totalErc20)}</p>
                        </div>
                    </div>
                    <div className={css.div}>
                        <p className={css.res}>Total NFTs</p>
                        <div style={{ display: "flex" }} className="flex items-center">
                            <p className={css.req} >{totalNfts}</p>
                        </div>
                    </div>

                </div>
                {/* <div className="flex justify-between w-full"> */}
                {/* <div className="grid grid-cols-4">
                    <div className="">
                        <p className={css.res}>ETH Balance</p>
                        <p className={css.req}>{parseFloat(balance).toFixed(3)} {getChainSymbol()}</p>
                    </div>

                    <div className="mt-0">
                        <p className={css.res}>ERC20 Balance</p>
                        <p className={css.req} >{formatinusd(totalErc20)}</p>
                    </div>
                    <div className="mt-0">
                        <p className={css.res}>ETH Value</p>
                        <p className={css.req} >{balanceUSD}</p>
                    </div>
                    <div className="mt-4">
                        <p className={css.res}>Total NFTs</p>
                        <p className={css.req} >{totalNfts}</p>
                    </div>
                </div> */}
                {/* </div> */}
            </div>

        </div >
    );

}

export default Glance;
