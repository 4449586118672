import React, { useState, useContext, useEffect } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { colors } from "../../../../../../1.resources/1.css/colors";
import { TbCoins } from "react-icons/tb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { callW3Api, getWnsDomain } from "../../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getNameTag } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/getLabel";
import { Tooltip } from "@material-tailwind/react";
import WnsLogo from "../../../../../0.global/logo/wnsLogo";
import { getChainPath } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { shortenaddress } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { formatinusd } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";

const Holders = ({ currentAddress }) => {
    const [tabSelected, setTabSelected] = useState("normal");
    const [holders, setHolders] = useState([]);
    const [decimals, setDecimals] = useState(0);
    const [price, setPrice] = useState(0);

    async function init() {
        let result = await callW3Api("/tokens/holders", { address: currentAddress });
        console.log(result);
        setHolders(result.holders);

        let result2 = await callW3Api("/tokens/details/ethplorer", { address: currentAddress });
        console.log(result2);
        setDecimals(result2.decimals);
        setPrice(result2.price ? result2.price.rate : 0);
    }

    useEffect(() => {
        if (currentAddress != "") {
            init()
        }
    }, [currentAddress])

    return (
        <div className="overflow-x-scroll">
            <Labels />
            <Mapping items={holders} decimals={decimals} price={price} />
        </div>
    )

}

export default Holders;

const Mapping = ({ items, decimals, price }) => {

    return (
        items.map((item, index) => (
            <div key={index} className="flex items-center justify-between border-b border-gray-200 py-4 px-10 w-[1200px] md:w-[1250px]">
                <div className="w-4/12 flex items-center gap-x-2">
                    <div className="w-[60px]">
                        <p className="">{index + 1}</p>
                    </div>
                    <div>
                        <GetDomain item={item} currentAddress={item.address} />
                    </div>
                </div>
                <div className="w-8/12 flex items-center justify-between gap-x-2">
                    <div className="w-4/12">
                        <p className="">{(convertToNumber(item.balance) / 10 ** decimals).toLocaleString()}</p>
                    </div>
                    <div className="w-4/12">
                        <p className="">{item.share}%</p>
                    </div>
                    <div className="w-4/12">
                        <p className="">{formatinusd((convertToNumber(item.balance) / 10 ** decimals) * price)}</p>
                    </div>
                </div>
            </div>
        ))
    )
}

const Labels = () => {

    return (
        <div className="flex items-center justify-between border-b border-gray-200 py-4 px-10 w-[1200px] md:w-[1250px]">
            <div className="w-4/12 flex items-center gap-x-2">
                <div className="w-[60px]">
                    <p className="text-gray-500 font-semibold">#</p>
                </div>
                <div>
                    <p className="text-gray-500 font-semibold">Address</p>
                </div>
            </div>
            <div className="w-8/12 flex items-center justify-between gap-x-2">
                <div className="w-4/12">
                    <p className="text-gray-500 font-semibold">Balance</p>
                </div>
                <div className="w-4/12">
                    <p className="text-gray-500 font-semibold">Share</p>
                </div>
                <div className="w-4/12">
                    <p className="text-gray-500 font-semibold">Value</p>
                </div>
            </div>
        </div>
    )
}

const GetDomain = ({ param, item, currentAddress, css, size, weight }) => {
    const [tag, setTag] = useState(null);
    const [domain, setDomain] = useState(null);
    const [address, setAddress] = useState(null);

    useEffect(() => {
        if (address != null && currentAddress != null) {
            getNameTag(address).then((nametag) => {
                if (nametag != "null") {

                    var doc = new DOMParser().parseFromString(nametag, "text/xml");

                    let name;
                    try {
                        name = doc.getElementsByTagName("span")[0].textContent;
                    } catch (e) {
                        name = nametag;
                    }

                    console.log(name);
                    setTag(name.length > 16 ? name.substring(0, 16) + "..." : name);
                } else {
                    getWnsDomain(address).then((domain) => {
                        if (domain != "null") {
                            setDomain(domain);
                        }
                    })
                }
            });

        }
    }, [address])

    useEffect(() => {
        setAddress(item.address);
    }, [])

    return (
        <div className={size == null ? `truncate text-md` : size == "lg" ? "truncate text-lg" : size == "xl" ? "truncate text-xl" : size == "sm" ? "truncate text-sm" : "truncate text-md"} >
            <div className={weight == null ? "" : "font-semibold"}>
                <Tooltip content={address} placement="top" className="bg-gray-500 py-1 truncate px-4" >
                    <div>
                        {tag != null ? (
                            <div className="flex gap-2 items-center">
                                <FontAwesomeIcon icon={['far', 'fa-file-code']} className="text-link" style={{ fontSize: "90%" }} />
                                <a href={getChainPath() + "/address/" + address} className={"text-link truncate"}>{tag.length > 15 ? tag.substring(0, 15) + "..." : tag}</a>
                            </div>

                        ) : (
                            domain != null ? (
                                <div className="flex items-center gap-2">
                                    <WnsLogo color={"#0171E2"} size={size == null ? "14px" : size == "lg" ? "16px" : size == "xl" ? "20px" : "14px"} />
                                    <a href={getChainPath() + "/address/" + address} className={"text-link truncate"}>{domain}</a>
                                </div>
                            ) : (
                                <a href={getChainPath() + "/address/" + address} className={"text-link truncate"}>{address == null ? "" : shortenaddress(address)}</a>
                            )
                        )
                        }
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}

function convertToNumber(n) {
    var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
    if (!/e/i.test(toStr)) {
        return n;
    }
    var [lead, decimal, pow] = n.toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
    return +pow < 0 ?
        sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal :
        sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
}