import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from "../../../../1.resources/1.css/colors";
import { timeToString } from "../../../../1.resources/2.js/0.global/0.smallfunctions/time";
import { getChainPath, getChainSymbol } from "../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { Tooltip } from "@material-tailwind/react";
import { callMoralisApi, getErc20Logo, getErc20Metadata, getErc721Metadata } from "../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { ethers } from "ethers";
import { GetDomain } from "../../../3.address/components/lower/partials/transactions/partials/txList";
import { shortenaddress } from "../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { BiCoin } from "react-icons/bi";

const Lower = ({ item, copyp, setCopyp, copyClipboard }) => {
    console.log(item);
    let css = {
        div: "flex items-center py-5 px-5 md:px-14 truncate border-t border-gray-200",
        reqdiv: "w-4/12 lg:w-3/12 truncate",
        req: "text-gray-500 md:text-md truncate",
        resdiv: "w-8/12 lg:w-9/12 truncate",
        res: "md:text-md truncate"
    }
    return (
        <div className="pt-0">
            {/* <p className="text-lg font-semibold px-5 md:px-14 pb-0">Transaction Details</p> */}
            <div className="flex items-center gap-x-2 px-5 md:px-14 pb-4">
                <FontAwesomeIcon icon={['fas', 'fa-circle-info']} className="text-gray-500 text-sm" />
                <p className="text-sm text-gray-500 ">This transaction is on the {getChainSymbol()} network</p>
            </div>

            {item != null ?
                (
                    <div>
                        <div className={`${css.div} bg-white`}>
                            <div className={css.reqdiv}>
                                <p className={css.req}>Block</p>
                            </div>
                            <div className={css.resdiv}>
                                {item.block_number == null ? (
                                    <p className={`${css.res} text-link}`}>-</p>
                                ) : (
                                    <a href="" className={`${css.res} text-main`}>{parseInt(item.block_number)}</a>
                                )}
                            </div>
                        </div>
                        <div className={`${css.div} bg-white`}>
                            <div className={css.reqdiv}>
                                <p className={css.req}>Timestamp</p>
                            </div>
                            <div className={css.resdiv}>
                                {item.block_number == null ? (
                                    <p className={`${css.res} text-link}`}>-</p>
                                ) : (
                                    <div className="flex gap-2 items-center">
                                        <FontAwesomeIcon icon={['far', 'fa-clock']} className="text-black" style={{ fontSize: "90%" }} />
                                        <p className={css.res}>{timeToString(new Date(item.block_timestamp).getTime()) + " (" + new Date(item.block_timestamp).toLocaleString() + ")"}</p>
                                    </div>)}
                            </div>
                        </div>
                        <div className={`${css.div} bg-white`}>
                            <div className={css.reqdiv}>
                                <p className={css.req}>From</p>
                            </div>
                            <div className={css.resdiv}>
                                <div className="flex items-center gap-2">
                                    <GetDomain param={"from"} item={item} currentAddress={""} size={window.innerWidth < 767 ? "md" : "md"} weight={"normal"} />
                                    {/* <a href={getChainPath() + "/address/" + item.from_address} className={`${css.res} text-link cursor-pointer`}>{item.from_address}</a> */}
                                    <Tooltip content={copyp} placement="bottom" className="bg-gray-500 text-xs py-1 px-4" >
                                        <div onClick={() => copyClipboard(item.from_address)}>
                                            <FontAwesomeIcon icon={['far', 'fa-copy']} className="text-main" style={{ fontSize: "90%" }} />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className={`${css.div} bg-white`}>
                            <div className={css.reqdiv}>
                                <p className={css.req}>To</p>
                            </div>
                            <div className={css.resdiv}>
                                <div className="flex items-center gap-2">
                                    <GetDomain param={"to"} item={item} currentAddress={""} size={window.innerWidth < 767 ? "md" : "md"} weight={"normal"} />

                                    {/* <a href={getChainPath() + "/address/" + item.to_address} className={`${css.res} text-link cursor-pointer`}>{item.to_address}</a> */}
                                    <Tooltip content={copyp} placement="bottom" className="bg-gray-500 text-xs py-1 px-4" >
                                        <div onClick={() => copyClipboard(item.to_address)}>
                                            <FontAwesomeIcon icon={['far', 'fa-copy']} className="text-main" style={{ fontSize: "90%" }} />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className={`${css.div} bg-white`}>
                            <div className={css.reqdiv}>
                                <p className={css.req}>Value</p>
                            </div>
                            <div className={css.resdiv}>
                                {item.receipt_status != "0" ? (
                                    <p className={`${css.res}`}>{item.value / 1e18} {getChainSymbol()}</p>
                                ) : (
                                    <div className="flex items-center gap-2">
                                        <p className={`${css.res}`}>{item.value / 1e18} {getChainSymbol()}</p>
                                        <p className="text-red-500 text-xs"> - Cancelled</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={`${css.div} bg-white`}>
                            <div className={css.reqdiv}>
                                <p className={css.req}>Transaction Fee</p>
                            </div>
                            <div className={css.resdiv}>
                                {item.block_number == null ? (
                                    <p className={`${css.res} text-link}`}>-</p>
                                ) : (
                                    <p className={`${css.res}`}>{(item.receipt_gas_used * item.gas_price / 1e18).toFixed(5)} {getChainSymbol()}</p>
                                )}
                            </div>
                        </div>
                        <div className={`${css.div} bg-white`}>
                            <div className={css.reqdiv}>
                                <p className={css.req}>Gas Price</p>
                            </div>
                            <div className={css.resdiv}>
                                {item.block_number == null ? (
                                    <p className={`${css.res}`}>{(item.gas_price / 1e9).toFixed(2)} GWEI</p>
                                ) : (
                                    <p className={`${css.res}`}>{(item.gas_price / 1e9).toFixed(2)} GWEI</p>
                                )}
                            </div>
                        </div>
                        <div className={`${css.div} bg-white`}>
                            <div className={css.reqdiv}>
                                {item.block_number == null ? (
                                    <p className={css.req}>Gas Limit</p>
                                ) : (
                                    <p className={css.req}>Gas Limit & Usage</p>
                                )}
                            </div>
                            <div className={css.resdiv}>
                                {item.block_number == null ? (
                                    <p className={`${css.res}`}>{parseInt(item.gas).toLocaleString()}</p>
                                ) : (
                                    <p className={`${css.res}`}>{parseInt(item.gas).toLocaleString()} | {parseInt(item.receipt_gas_used).toLocaleString()}  ({(item.receipt_gas_used / item.gas * 100).toFixed(2)}%)</p>
                                )}
                            </div>
                        </div>
                        {item.decoded.erc20 != null && item.decoded.erc20.length > 0 ? (
                            <div className={`block md:flex items-center py-5 px-5 md:px-14 truncate border-t border-gray-200`}>
                                <div className={'w-full lg:w-3/12 truncate'}>
                                    <p className={css.req}>Erc20 Transfers</p>
                                </div>
                                <div className={`w-full lg:w-9/12 truncate`}>
                                    {item.block_number == null ? (
                                        <Transfers item={item} type={"erc20"} />
                                    ) : (
                                        <Transfers item={item} type={"erc20"} />
                                    )}
                                </div>
                            </div>
                        ) : null}
                        {item.decoded.erc721 != null && item.decoded.erc721.length > 0 ? (
                            <div className={`block md:flex items-center py-5 px-5 md:px-14 truncate border-t border-gray-200`}>
                                <div className={'w-full lg:w-3/12 truncate'}>
                                    <p className={css.req}>Erc721 Transfers</p>
                                </div>
                                <div className={`w-full lg:w-9/12 truncate`}>
                                    {item.block_number == null ? (
                                        <Transfers item={item} type={"erc721"} />
                                    ) : (
                                        <Transfers item={item} type={"erc721"} />
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div />
                )}

        </div>
    );

}

export default Lower;


const Transfers = ({ item, type }) => {
    const [metadata, setMetadata] = useState(null);
    const [more, setMore] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(item);
        if (item != null) {
            if (type == "erc20") {
                let array;
                if (item.decoded.erc20.length > 5) {
                    array = item.decoded.erc20.slice(0, 5);
                    setMore(true);
                } else {
                    array = item.decoded.erc20;
                }
                let metadataPromise = []
                array.map((item, index) => {
                    metadataPromise.push(getErc20Metadata(item.contract));
                });
                Promise.all(metadataPromise).then((result) => {
                    console.log(result);
                    setMetadata(result);
                    setLoading(false);
                })
            } else {
                let array;
                if (item.decoded.erc721.length > 5) {
                    array = item.decoded.erc721.slice(0, 5);
                    setMore(true);
                } else {
                    array = item.decoded.erc721;
                }
                let metadataPromise = []
                item.decoded.erc721.map((item, index) => {
                    metadataPromise.push(getErc721Metadata(item.contract, item.value));
                });
                Promise.all(metadataPromise).then((result) => {
                    console.log(result);
                    setMetadata(result);
                    setLoading(false);
                })
            }
        }
    }, [item])

    return (
        <div className="overflow-x-scroll">
            {loading ? (
                <div className="flex items-center justify-center py-4 border-b border-gray-200">
                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-main animate-spin" />
                </div>
            ) : (
                metadata != null ? (
                    metadata.map((meta, index) => (
                        <div key={index}>
                            <div className="flex items-center justify-between py-4 border-b border-gray-200 gap-x-10">
                                <div className="flex gap-x-6 items-center">
                                    <ErcImage meta={meta} type={type} />
                                    {/* // <img src={type == "erc20" ? meta?.logo : meta?.normalized_metadata?.image}
                            //     className="w-16 h-16 rounded-2xl"
                            //     onError={(e) => { e.target.src = "" }} /> */}
                                    <div>
                                        <div className="flex items-center gap-x-2">
                                            <a href={type == "erc20" ? `/eth/token/${item.decoded.erc20[index].contract}` : `https://opensea.io/assets/ethereum/${meta?.token_address}`} target={type == "erc20" ? "" : "_blank"} className="text-md font-semibold text-main">{meta.name}</a>
                                            {/* <p className="text-md text-gray-500">{meta.symbol}</p> */}
                                        </div>
                                        <div className="flex items-center gap-x-4 mt-2">
                                            <div className="flex items-center gap-x-2 bg-gray-50 rounded-xl px-4 py-1.5 border border-gray-200 w-fit">
                                                <p className="truncate text-md text-gray-500">From:</p>
                                                <GetDomain param={"from"} item={type == "erc20" ? item.decoded.erc20[index] : item.decoded.erc721[index]} currentAddress={""} size={"sm"} />
                                            </div>

                                            <div className="flex items-center gap-x-2 bg-gray-50 rounded-xl px-4 py-1.5 border border-gray-200 w-fit">
                                                <p className="truncate text-md text-gray-500">To:</p>

                                                <GetDomain param={"to"} item={type == "erc20" ? item.decoded.erc20[index] : item.decoded.erc721[index]} currentAddress={""} size={"sm"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {type == "erc20" ? (
                                        <p className="text-md font-semibold">{(parseFloat(parseFloat(ethers.utils.formatUnits(item.decoded.erc20[index].value, meta.decimals)).toFixed(3))).toLocaleString()} {meta.symbol}</p>
                                    ) : (
                                        <a href={`https://opensea.io/assets/ethereum/${meta?.token_address}/${meta?.token_id}`} target="_blank" className="text-md font-semibold text-main">#{meta?.token_id?.length > 16 ? meta?.token_id?.substring(0, 16) + "..." : meta?.token_id}</a>
                                    )}

                                </div>
                            </div>

                        </div>
                    ))
                ) : (null))}
            {more ? (
                <div className="flex items-center justify-start py-4">
                    <p className="text-md text-gray-500">showing 5 of {type == 'erc20' ? item.decoded.erc20.length : item.decoded.erc721.length} items</p>
                </div>
            ) : (null)}
        </div>
    )

}

const ErcImage = ({ meta, type }) => {
    const [logo, setLogo] = useState("");

    async function init() {
        // let key = "VFF6ID9XI21HUTHA8ZXADXEZ847VSVE6UD";

        // let result = await (await fetch(`https://api.etherscan.io/api?module=token&action=tokeninfo&contractaddress=${meta.contract}&apikey=${key}`)).json();
        console.log(meta);
        if (type == "erc20" && meta.logo == null) {
            let result = await getErc20Logo(meta.address);
            console.log(result);
            if (result != "null" && result != null) {
                setLogo(result);
            }
        }
    }
    useEffect(() => {
        init()
    }, [])
    return (
        <div>
            {type == "erc20" ? (
                meta?.logo == null ? (
                    logo == "" ? (
                        <div className="bg-gray-200 w-10 h-10 flex justify-center items-center rounded-2xl">
                            <BiCoin className="text-gray-500 text-2xl" />
                        </div>
                    ) : (
                        <img src={logo} className="w-10 h-10 rounded-2xl" onError={(e) => { e.target.src = ""; setLogo("") }} />
                    )
                ) : (
                    <img src={meta?.logo} className="w-10 h-10 rounded-2xl" onError={(e) => { e.target.src = ""; setLogo("") }} />
                )
            ) : (
                meta?.normalized_metadata?.image == null ? (
                    <div className="bg-gray-200 w-16 h-16 flex justify-center items-center rounded-2xl">
                        <FontAwesomeIcon icon={["fas", "fa-image"]} className="text-gray-500 text-2xl" />
                    </div>
                ) : (
                    <img src={meta?.normalized_metadata?.image?.includes("ipfs://") ? "https://ipfs.io/ipfs/" + meta?.normalized_metadata?.image?.substring(7) : meta?.normalized_metadata?.image} className="w-16 h-16 rounded-2xl" onError={(e) => { e.target.src = ""; setLogo("") }} />
                )
            )}
        </div>
    )
}