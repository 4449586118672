import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloudContracts from "../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { colors } from "../../../1.resources/1.css/colors";
import WnsLogo from "../logo/wnsLogo";
import { TbFileInvoice } from "react-icons/tb";
import { BiUser } from "react-icons/bi";
import { getChainPath } from "../../../1.resources/2.js/0.global/0.smallfunctions/chain";

const punycode = require('punycode/');

class MobileSearch extends React.Component {

    componentDidMount() {
        document.getElementById("mobileSearchModal").style.width = document.getElementById("mobileSearch").scrollWidth + "px";
    }
    render() {
        return (
            <HeaderSearchFunc setSearchActive={this.props.setSearchActive} searchActive={this.props.searchActive} />
        )
    }
}


export default MobileSearch;


const HeaderSearchFunc = ({ }) => {
    const [searchValue, setSearchValue] = useState("")
    const [searchAddress, setSearchAddress] = useState("0x");

    const [searchStatus, setSearchStatus] = useState("error")
    const [searchStatusColor, setSearchStatusColor] = useState("")
    const [searchLoading, setSearchLoading] = useState(false)

    async function searchDomain(value) {
        if (!value.includes(".")) {
            value = value.replace(" ", "").toLowerCase();
            setSearchLoading(true);

            let status;
            if (value.length < 3 || value.length > 15) {
                status = "error";
            } else {
                try {
                    let address = await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full").wnsResolverContract.resolveName(punycode.toASCII(value), "");
                    console.log(address);
                    setSearchAddress(address);
                    status = "ok";
                } catch (e) {
                    status = "error";
                }
            }
            setSearchStatus(status);
            setSearchLoading(false);
        }
    }

    async function search(value) {
        setSearchValue(value);
        setSearchAddress("0x");
        searchDomain(value);

    }

    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault(); // Prevent form submission or page reload
            // Optionally, you can access the input value
            const inputValue = event.target.value;

            go()
        }
    }

    async function go() {
        if (searchAddress != "0x") {
            window.location.href = getChainPath() + "/address/" + searchAddress;
        }

        if (searchValue.substring(0, 2) == "0x" && searchValue.length == 42) {
            window.location.href = getChainPath() + "/address/" + searchValue;
        }

        if (searchValue.substring(0, 2) == "0x" && searchValue.length == 66) {
            window.location.href = getChainPath() + "/tx/" + searchValue;
        }
    }

    return (
        <div id="searchDiv" className="bg-white min-h-screen mt-0 pt-8 px-5">
            <div className="flex items-center w-full justify-between gap-x-2">
                <div className="bg-white rounded-xl flex flex-between items-center border-2 border-gray-200 w-full" style={{ zIndex: "100000" }} id="mobileSearch">
                    <FontAwesomeIcon icon={['fas', 'fa-search']} style={{ color: colors.gray400, fontSize: "100%" }} className="pl-4" />
                    <input id="searchInput" type="text" onChange={(e) => { search(e.target.value) }} value={searchValue} placeholder="Search address / tx / domain" className="bg-transparent text-black w-full outline-none pl-4 py-2 text-lg" onKeyDown={(event) => handleKeyDown(event)} ></input>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-right']} style={{ color: colors.gray400, fontSize: "100%" }} className="pr-4 pl-4" />
                </div>
                <div className="w-fit px-4 py-2 rounded-full bg-main" onClick={() => go()}>
                    <FontAwesomeIcon icon={["fas", "fa-arrow-right"]} className="text-white text-md" />
                </div>
            </div>
            <div className="rounded-xl mt-2 bg-white" style={{ zIndex: "10", position: "absolute" }} id="">



                <div style={{ padding: "0%", display: searchValue.length > 0 && searchValue.substring(0, 1) != 0 && searchValue.substring(0, 2) != "0x" ? "block" : "none" }} className="rounded-xl border-2 border-gray-200" id="mobileSearchModal">
                    <div>
                        <p className="text-sm text-gray-400 font-semibold px-4 py-2">Address</p>
                        <a className="bg-white hover:bg-neutral-100 cursor-pointer flex justify-between items-center text-sm px-4 py-2 rounded-b-xl w-full" href={getChainPath() + "/address/" + searchAddress}>
                            <div style={{ width: "70%" }} className="flex items-center gap-x-3 truncate">
                                <div className="w-[30px] flex justify-center">
                                    <WnsLogo />
                                </div>
                                <div>
                                    <p>
                                        <span className="font-semibold text-black text-lg">{searchValue}</span>
                                        <span className="text-black text-lg">.web3</span>
                                    </p>
                                    <span className="font-semibold text-gray-500 text-sm truncate">{searchAddress}</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end" }} className="gap-x-4">
                                <div style={{ display: searchLoading ? "block" : "none" }}>
                                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ color: "#606060", fontSize: "100%" }} id="arrowicon" spin />
                                </div>
                                <div style={{ display: searchLoading ? "none" : "block" }}>
                                    <FontAwesomeIcon icon={['fas', searchStatus == "error" ? "fa-circle-info" : 'fa-circle-check']} className={searchStatus == "error" ? "text-yellow-500" : "text-blue-500"} id="arrowicon" />
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="" id="arrowicon" />
                                </div>
                            </div>
                        </a>
                        {/* <a className="bg-white hover:bg-neutral-100 cursor-pointer flex justify-between items-center text-sm px-4 py-2 rounded-b-xl w-full" href={getChainPath() + "/address/" + searchValue}>
                            <div style={{ width: "70%" }} className="flex items-center gap-x-3">
                                <BiUser className="text-2xl w-[30px]" id="arrowicon" />
                                <span className="font-semibold text-black text-lg truncate">{searchValue}</span>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="" id="arrowicon" />
                            </div>
                        </a> */}
                    </div>

                    {/* <div className="border-t border-t-gray-200 mt-4">
                        <p className="text-sm text-gray-400 font-semibold px-4 py-2">Transaction</p>
                        <a className="bg-white hover:bg-neutral-100 cursor-pointer flex justify-between items-center text-sm px-4 py-2 rounded-b-xl w-full" href={getChainPath() + "/tx/" + searchValue}>
                            <div style={{ width: "70%" }} className="flex items-center gap-x-3">
                                <TbFileInvoice className="text-xl w-[30px]" id="arrowicon" />
                                <span className="font-semibold text-black text-lg truncate">{searchValue}</span>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="" id="arrowicon" />
                            </div>
                        </a>
                    </div> */}

                </div>



            </div>
        </div >
    );

}