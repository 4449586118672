export function shortenaddress(xstr) {
    const myArr = xstr.toString().substring(0, 6);
    const myArrlast = xstr.toString().substring(38, 42);
    const final = myArr + "..." + myArrlast
    return final
}

export const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
}

export function normalizeFnName(name) {
    let array = name.split(/(?=[A-Z])/);
    let joined = array.join(" ");
    return joined.charAt(0).toUpperCase() + joined.slice(1);
}