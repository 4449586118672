import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Titles from "./partials/title.js";
import { colors } from "../../../../1.resources/1.css/colors.js";
import { CgDetailsMore } from "react-icons/cg";
import { BiDetail } from "react-icons/bi";
import Glance from "./partials/glance.js";
import { getErc20Logo, getErc20Metadata, getErc20Stats } from "../../../../1.resources/2.js/0.global/3.api/callW3Api.js";
const { TbCoins } = require("react-icons/tb");

const Upper = ({ tabSelected, setTabSelected, totalErc20, txDetails, copyp, setCopyp, copyClipboard }) => {
    const [currentAddress, setCurrentAddress] = useState("");
    const [details, setDetails] = useState({});

    useEffect(() => {
        setCurrentAddress(window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1));
    }, [])

    async function init() {
        let address = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
        let [details] = await Promise.all([
            getErc20Stats(address)
        ])
        setDetails(details);
    }

    useEffect(() => {
        init();
    }, [])

    return (

        <div className="bg-gray-100 flex justify-center pt-6 md:pt-8 bg-white border-b-2 border-gray-200">
            <div className="w-full lg:w-[1400px] px-5 md:px-10 lg:px-20">
                <Titles details={details} currentAddress={currentAddress} />
                <Glance currentAddress={currentAddress} details={details} />

                <div className="flex items-center gap-x-10 mt-12">
                    <button onClick={() => setTabSelected("transactions")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "transactions" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                        <FontAwesomeIcon icon={['fas', 'fa-list']} style={{ color: tabSelected == "transactions" ? colors.main : colors.gray500 }} />
                        <p className={`text-md ${tabSelected == "transactions" ? "text-main" : "text-gray-500"}`}>Transactions</p>
                    </button>
                    <button onClick={() => setTabSelected("holders")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "holders" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                        <TbCoins style={{ color: tabSelected == "holders" ? colors.main : colors.gray500 }} className="text-xl" />
                        <p className={`text-md ${tabSelected == "holders" ? "text-main" : "text-gray-500"}`}>Holders</p>
                    </button>
                </div >
            </div>
        </div>
    );

}

export default Upper;
