import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { callW3Api } from "../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getChain, getChainSymbol } from "../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { formatinusd } from "../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import HeaderLinks from "./headerLinks";

const UpperHeader = ({ }) => {
    const [stats, setStats] = useState({ price: 0, gas: 0 })

    async function init() {
        let result = await callW3Api("/stats/pricegas", { chain: getChain().chain });
        console.log(result);
        setStats(result);
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <div className="w-full flex justify-between bg-white">
            <div className="flex items-center gap-x-6 " >
                <div className="flex items-center gap-x-2">
                    <p className="text-sm text-gray-500">{getChainSymbol() == "MATIC" ? "MATIC" : "ETH"} Price:</p>
                    <p className="text-sm text-blue-500">{formatinusd(stats.price)}</p>
                </div>
                <div className="flex items-center gap-x-2">
                    <FontAwesomeIcon icon={['fas', 'fa-gas-pump']} className="text-gray-500 text-xs" />
                    <p className="text-sm text-gray-500">Gas:</p>
                    <p className="text-sm text-blue-500">{stats.gas} Gwei</p>
                </div>
            </div >

            <div>
                <HeaderLinks />
            </div>
        </div>
    );

}

export default UpperHeader;
