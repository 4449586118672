import axios, * as others from 'axios';


/* export async function callW3Api(urlpath, body) {
    let url = process.env.REACT_APP_API_URL + urlpath;
    let response = await (await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })).json();

    console.log(response);
    return response.value;
} */

export async function callW3Api(urlpath, body) {
    let url = process.env.REACT_APP_API_URL + urlpath;
    let response = await axios({
        method: 'post',
        url: url,
        data: body
    });
    return response.data.value;
}

export async function callMoralisApi(path) {
    console.log('https://deep-index.moralis.io/api/v2' + path);
    const options = {
        method: 'GET',
        url: 'https://deep-index.moralis.io/api/v2' + path,
        headers: { accept: 'application/json', 'X-API-Key': 'qh9GURBnTigYGpYJ51Xt0GeDBd7aSAseXafbWW9SLNSiwzsM0HtK1Iat3SKYkl2j' }
    };

    let result = await axios.request(options);

    return result.data;
}


export async function getWnsDomain(address) {
    let result = await (await fetch("https://resolver.wnsdomains.io/resolve/address=" + address)).json()
    return result.value;
}

export async function getWnsAddress(domain) {
    let result = await (await fetch("https://resolver.wnsdomains.io/resolve/name=" + domain)).json()
    return result.value;
}

export async function getErc20Metadata(address) {
    let result = await callMoralisApi(`/erc20/metadata?chain=eth&addresses%5B0%5D=${address}`);

    return result[0];
}

export async function getErc20Logo(address) {
    let key = "VFF6ID9XI21HUTHA8ZXADXEZ847VSVE6UD";

    // let result2 = await callW3Api('/tokens/details', { address: address });
    // console.log(result2);

    let result = await callMoralisApi(`/erc20/metadata?chain=eth&addresses%5B0%5D=${address}`);

    if (result[0].logo) {
        return result[0].logo;
    } else {
        let result2 = await callW3Api('/tokens/details', { address: address });
        console.log(result2);
        return result2 != "error" ? result2.logo : "null";
    }
}

export async function getErc20Stats(address) {
    let result2 = await callW3Api('/tokens/details', { address: address });
    console.log(result2);
    return result2 != "error" ? result2 : "null";
}

export async function getErc721Metadata(address, token) {


    let result = await callMoralisApi(`/nft/${address}/${token}?chain=eth&format=decimal&normalizeMetadata=true&media_items=true`);
    return result;
}

export async function callEthplorerApi(path) {
    let result = await (await fetch(`https://api.ethplorer.io${path}?apiKey=EK-e24bh-BEv23Lo-YWymN`)).json();
    return result;
}