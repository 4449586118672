import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Titles from "./partials/titles";
import Glance from "./partials/glance";
import { colors } from "../../../../1.resources/1.css/colors";
import { TbCoins } from "react-icons/tb";

const Upper = ({ tabSelected, setTabSelected, totalErc20, totalNfts, valid }) => {
    const [currentAddress, setCurrentAddress] = useState("");

    useEffect(() => {
        setCurrentAddress(window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1));
    }, [])


    return (

        <div className="bg-gray-100 flex justify-center pt-6 md:pt-8 bg-white border-b-2 border-gray-200">
            <div className="w-full lg:w-[1400px] px-5 md:px-10 lg:px-20">
                <Titles currentAddress={currentAddress} valid={valid} />
                <Glance currentAddress={currentAddress} totalErc20={totalErc20} totalNfts={totalNfts} valid={valid} />

                <div className="flex items-center gap-x-10 mt-12">
                    <button onClick={() => setTabSelected("transactions")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "transactions" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                        <FontAwesomeIcon icon={['fas', 'fa-list']} style={{ color: tabSelected == "transactions" ? colors.main : colors.gray500 }} />
                        <p className={`text-md ${tabSelected == "transactions" ? "text-main" : "text-gray-500"}`}>Transactions</p>
                    </button>
                    <button onClick={() => setTabSelected("tokens")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "tokens" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                        <TbCoins style={{ color: tabSelected == "tokens" ? colors.main : colors.gray500 }} className="text-xl" />
                        <p className={`text-md ${tabSelected == "tokens" ? "text-main" : "text-gray-500"}`}>Tokens</p>
                    </button>
                    <button onClick={() => setTabSelected("nfts")} className={`font-semibold flex items-center gap-x-3 pb-4 ${tabSelected == "nfts" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                        <FontAwesomeIcon icon={['fas', 'fa-image']} style={{ color: tabSelected == "nfts" ? colors.main : colors.gray500 }} />
                        <p className={`text-md ${tabSelected == "nfts" ? "text-main" : "text-gray-500"}`}>NFTs</p>
                    </button>
                </div >
            </div>
        </div >
    );

}

export default Upper;
