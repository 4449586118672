import React from 'react';
import Logo from "../../../1.resources/3.files/logo/logo2.png";
import Banner from './banner';
import HeaderLinks from './headerLinks';
import HeaderRight from './headerRight';
import HeaderSearch from './headerSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MobileSidebar from './mobileSidebar';
import MobileSearch from './mobileSearch';
import { getChainLogo } from '../../../1.resources/2.js/0.global/0.smallfunctions/chain';
import UpperHeader from './upper';

const Header = () => {
    const [mobileSidebar, setMobileSidebar] = React.useState(false);
    const [mobileSearch, setMobileSearch] = React.useState(false);
    return (
        <div style={{ position: "relative" }}>
            <div className='block md:hidden'>
                {/* <div className="w-full py-3 px-5 border-b-2 border-gray-200">
                    <UpperHeader />
                </div> */}
                <div id="mobileHeader" className="flex md:hidden items-center border-b-2 border-b-gray-200 px-5 py-2" style={{ background: "#fff", zIndex: "1000000000000000", justifyContent: "space-between" }}>

                    <a href="/" style={{ display: "flex", alignItems: "center", cursor: "pointer" }} >
                        <img src={Logo} className="w-12 h-12" style={{ borderRadius: "20px" }} />
                        {/* <p style={{ color: "#000", marginLeft: '5%', fontSize: "120%", fontWeight: "bold", whiteSpace: "nowrap" }}>W3 <span className='text-gray-500 font-semibold'>Explorer</span></p> */}
                    </a>
                    <div className="flex gap-x-8 items-center">
                        <div onClick={() => setMobileSearch(!mobileSearch)} className="w-8 h-8 flex items-center justify-center" style={{ borderRadius: "20px" }}>
                            <FontAwesomeIcon icon={['fas', "fa-magnifying-glass"]} style={{ color: "#000", fontSize: "120%" }} id="arrowicon" />
                        </div>
                        <div onClick={() => setMobileSidebar(!mobileSidebar)} style={{}}>
                            <img src={getChainLogo()} className="w-8 h-8" style={{ borderRadius: "20px" }} />
                        </div>
                        {/* <div onClick={() => window.location = "/cart"}>
                        <FontAwesomeIcon icon={['fas', "fa-shopping-bag"]} style={{ color: "#000", fontSize: "120%", marginLeft: "20px" }} id="arrowicon" />
                    </div>
                    <div onClick={() => setMobileSidebar(!mobileSidebar)} style={{ marginLeft: "20px" }}>
                        <FontAwesomeIcon icon={['fas', 'fa-bars']} style={{ color: "#000", fontSize: "120%" }} id="arrowicon" />
                    </div> */}
                    </div>
                </div>
            </div>

            <div>
                {/* <Banner /> */}
                <div className='hidden md:block' >

                    <div className="bg-gray-100 flex justify-center py-4 bg-white border-b-2 border-gray-200">
                        <div className="w-full lg:w-[1400px] px-10 lg:px-20">
                            <div className='w-full flex items-center gap-x-2 md:gap-x-8 justify-between'>
                                <img src={Logo} alt="Logo" className="w-12 h-12 bg-black cursor-pointer" style={{ borderRadius: "20px" }} onClick={() => window.location = "/"} />

                                <div id="" className='w-full'>
                                    <HeaderSearch />
                                </div>
                                <div>
                                    <HeaderRight />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-100 flex justify-center py-3 bg-white border-b-2 border-gray-200">
                        <div className="w-full lg:w-[1400px] px-10 lg:px-20">
                            <UpperHeader />
                        </div>
                    </div>
                    {/* <div className='flex justify-center bg-white'>
                        <div className='w-[600px] py-2'>
                           
                        </div>
                    </div> */}
                </div>
            </div>



            <div style={{ width: "100%" }} className={window.innerWidth < 1000 ? (mobileSidebar || mobileSearch ? "slider slide-in block md:hidden" : "slider slide-out block md:hidden") : ""}>
                {mobileSidebar ? (
                    <MobileSidebar setMobileSidebar={setMobileSidebar} />
                ) : (<div />)}
                {mobileSearch ? (
                    <MobileSearch mobileSearch={mobileSearch} setMobileSearch={setMobileSearch} />
                ) : (<div />)}
            </div>
        </div >
    )
}

export default Header;
