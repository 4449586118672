import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from "@material-tailwind/react";
import { callW3Api } from "../../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getChain } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { ethers } from "ethers";
import { GetSkeletons } from "../transactions/transactions";
import { formatinusd, tokenToUsdOnly } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { HiBadgeCheck } from "react-icons/hi";
import { BiCoin } from "react-icons/bi";
import EmptyImg from "../../../../../../1.resources/3.files/images/empty.png";

const Tokens = ({ setTotalErc20 }) => {
    const limit = 25;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(true);
    const [page, setPage] = useState(1);

    async function init() {
        setLoading(true);
        let result = await callW3Api("/address/erc20/get", { chain: getChain().chain, address: window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1), limit: 25 });
        console.log(result);
        setTotal(result.length);
        let valid = []
        result.map(item => {
            if (item.logo != null) {
                valid.push(item);
            }
            item["balanceUSD"] = "0";
        })

        console.log(valid.length, result.length);

        let pricesPromise = []
        valid.map(item => {
            pricesPromise.push(tokenToUsdOnly(ethers.utils.formatUnits(item.balance, item.decimals), item.symbol));
        })
        let prices = await Promise.all(pricesPromise);

        valid.map((item, index) => {
            if (isNaN(prices[index])) {
                item["balanceUSD"] = "0.000000000000001";
            } else {
                item["balanceUSD"] = prices[index];
            }
        })

        let final = []
        result.map(item => {
            final.push(item);
        })

        let totalBalance = 0
        final.map(item => {
            if (item.balanceUSD == "0.000000000000001" || item.balanceUSD == "0") {
                item.verified = false;
            } else {
                item.verified = true;
            }
            totalBalance += parseFloat(item.balanceUSD);
        })
        setTotalErc20(totalBalance);
        let sorted = final.sort(({ balanceUSD: a }, { balanceUSD: b }) => b - a);
        setItems(sorted);
        setLoading(false);
    }

    useEffect(() => {
        init();
    }, [])

    async function next() {
        if (page * limit < total) {
            setPage(page + 1);
        }
    }

    async function previous() {
        if (page > 1) {
            setPage(page - 1);
        }
    }



    return (
        <div>
            <div className="rounded-xl bg-white">
                <div className="px-0">
                    {/* <div className="border-b border-b-gray-200 py-8 px-10 pt-8">
                        <p className="font-semibold text-xl">Tokens</p>
                        <p className="text-gray-500 text-lg mt-1">All Tokens owned by this address</p>

                    </div> */}
                    <div className="pt-2">
                        {loading ? (
                            <GetSkeletons paramsArray={[...Array(4).keys()]} />
                        ) : (
                            items.length != 0 ? (
                                <div>
                                    <div className="flex items-center justify-between px-10 border-b-2 border-gray-200 pb-4 pt-2">
                                        <p className="text-gray-500 text-md font-semibold">Token </p>
                                        <p className="text-gray-500 text-md font-semibold">Balance </p>
                                    </div>
                                    <GetMapping items={items.slice((page * limit - limit), page * limit)} />
                                </div>
                            ) : (
                                <div className="flex justify-center items-center">
                                    <div>
                                        <img src={EmptyImg} className="w-80" />
                                        <div className="bg-white rounded-lg p-2 flex justify-center items-center my-2 pb-10">
                                            <div>
                                                <p className="text-black pl-1 text-2xl font-semibold text-center">No tokens</p>
                                                <p className="text-gray-500 pl-1 text-lg text-center">There are no tokens in this account</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                    {total > 0 ? (
                        <div className="flex justify-between items-center mt-4 pt-4 pb-4 border-t border-t-gray-200 text-gray-500 px-5 md:px-10">
                            <div className="w-4/12">
                                <p className="text-md">Showing <span className="">{items.length}</span> of <span className="">{total}</span> items</p>
                            </div>
                            <div className="w-4/12 flex items-center justify-center">
                                <p className="text-md text-gray-500">Page {page}</p>
                            </div>
                            <div className="flex w-4/12 justify-end">
                                <a href="#transactions" onClick={() => previous()} className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-md font-semibold text-gray-700 hover:bg-gray-50" style={{ display: page == 1 ? "none" : "block" }}>Previous
                                </a>
                                <a href="#transactions" onClick={() => next()} className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-md font-semibold text-gray-700 hover:bg-gray-50" style={{ display: page * limit < total ? "block" : "none" }}>Next
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}

                </div>
            </div >
        </div>
    );
}

export default Tokens;

const GetMapping = ({ items }) => {
    return (
        items.map((item, index) => (
            <a key={index} className="flex justify-between items-center py-4 px-5 md:px-10 border-b border-gray-200" href={"/eth/token/" + item.token_address}>
                <div className="flex justify-between items-center gap-x-4 md:gap-x-8">
                    <div>
                        {item.logo == null ? (
                            <div className="bg-gray-400 w-10 h-10 flex justify-center items-center rounded-3xl">
                                <BiCoin className="text-white text-xl" />
                            </div>
                        ) : (
                            <img src={item.logo} className="w-10 h-10" />
                        )}
                    </div>
                    <div className="">
                        <div className="flex items-center gap-x-2">
                            <p className="text-lg font-semibold">{item.name.substring(0, 20) + (item.symbol.length > 20 ? "..." : "")}</p>
                            {item.verified ? (
                                <HiBadgeCheck className="text-main" />
                            ) : (null)}
                        </div>
                        <p className="text-gray-500">{item.symbol.substring(0, 5) + (item.symbol.length > 5 ? "..." : "")}</p>
                    </div>
                </div>
                <div>
                    <div className="text-right">
                        <p className="text-lg font-semibold">{parseFloat(parseFloat(ethers.utils.formatUnits(item.balance, item.decimals))).toFixed(4) + " " + item.symbol.substring(0, 5) + (item.symbol.length > 5 ? "..." : "")}</p>
                        <p className="text-gray-500">{item.balanceUSD == "0.000000000000001" || item.balanceUSD == "0" ? "-" : formatinusd(item.balanceUSD)}</p>
                    </div>
                </div>
            </a>
        ))
    )
}