import React, { useState, useContext, useEffect } from "react";
import HeaderSearch from "../0.global/header/headerSearch";
import Logo from "../../1.resources/3.files/logo/logo2_white.png";
import HeroImg from "../../1.resources/3.files/images/herogradient2.png";
import { allChains, getChain, getChainLogo, getChainName, getChainSymbol } from "../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatinusd, usdToEth } from "../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { callMoralisApi, callW3Api } from "../../1.resources/2.js/0.global/3.api/callW3Api";

const Top = ({ }) => {

    return (
        <div>
            <div className="w-full min-h-screen" >

                <div className="w-full flex justify-center py-4 bg-white">

                    <div className="w-full lg:w-[1400px] px-5 md:px-10 lg:px-20 py-10">

                        <div className="">

                            <div className="mt-0">
                                <TopErc20 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Top;

const TopErc20 = ({ chain }) => {
    const [items, setItems] = useState([]);

    async function init() {
        let result;
        if (window.location.pathname == "/top/erc20") {
            result = await callMoralisApi("/market-data/erc20s/top-tokens", {});
        } else if (window.location.pathname == "/top/erc721") {
            result = await callMoralisApi("/market-data/nfts/top-collections", {});
        } else if (window.location.pathname == "/top/erc20/gainers") {
            result = await callMoralisApi("/market-data/erc20s/top-movers", {});
        } else if (window.location.pathname == "/top/erc721/gainers") {
            result = await callMoralisApi("/market-data/nfts/hottest-collections", {});
        }
        if (window.location.pathname.includes("erc20/gainers")) {
            result = result.gainers;
        }
        if (window.location.pathname.includes("/top/erc20")) {
            result = result.filter((item, index) => { return result.findIndex(obj => obj.contract_address === item.contract_address) === index; })
        }
        console.log(result);
        setItems(result);

    }

    useEffect(() => {
        init();
    }, [])

    return (
        <div>
            <div className="flex items-center justify-between">
                <div>
                    <p className="text-4xl font-bold uppercase">
                        {
                            window.location.pathname == "/top/erc20" ? "Top Tokens" : window.location.pathname == "/top/erc721" ? "Top NFT Collections" : window.location.pathname == "/top/erc20/gainers" ? "Top Gainers" : window.location.pathname == "/top/erc721/gainers" ? "Top NFT Gainers" : null
                        }</p>
                    <p className="text-gray-500 mt-2">
                        {window.location.pathname == "/top/erc20" ? "Top Erc20 tokens by market cap" : window.location.pathname == "/top/erc721" ? "Top Erc721 collections by market cap" : window.location.pathname == "/top/erc20/gainers" ? "Top Erc20 tokens by price change" : window.location.pathname == "/top/erc721/gainers" ? "Top Erc721 collections by price change" : null}
                    </p>
                </div>
            </div>
            <div className="overflow-x-scroll">
                <div className="min-w-[700px] flex items-center justify-between gap-x-20 mt-0 ">
                    <div className="w-full">

                        {window.location.pathname.includes("erc20") ? (
                            <div>
                                <GetErc20Labels type={"erc20"} />
                                <GetErc20Mapping array={items} skip={1} />
                            </div>
                        ) : window.location.pathname.includes("erc721") ? (
                            <div>
                                <GetErc20Labels type={"erc721"} />
                                <GetErc721Mapping array={items} skip={1} />
                            </div>
                        ) : null}

                        <p className="text-gray-500 text-md mt-4">Showing {items.length} items</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

const GetErc721Mapping = ({ array, skip }) => {

    const mapping = array.map((item, index) =>
        <div key={index} className="flex items-center gap-x-4 my-10 hover:bg-gray-100 px-0" >
            <div className="w-[30px] lg:w-[60px]">
                <p className="text-left text-xl font-bold text-gray-500">{skip + index}</p>
            </div>
            <div className="w-11/12 flex items-center gap-x-8 ">
                <div className="w-16 h-16 rounded-3xl">
                    <img src={item.collection_image} className="rounded-lg" />
                </div>
                <div className="flex items-center justify-between w-full">
                    <div className="w-4/12 md:w-6/12">
                        <p className="text-black text-lg font-bold flex truncate">{item.collection_title}</p>
                        <p className="text-gray-500 text-sm">{item.token_symbol}</p>
                    </div>
                    <div className="w-8/12 md:w-6/12 flex items-center justify-between">
                        <p className="text-lg font-semibold">{formatinusd(item.market_cap_usd)}</p>

                        <div className="flex flex-col items-end">
                            <EthValue value={item.floor_price_usd} />
                            {/* <p className="text-lg font-semibold">{usdToEth(item.floor_price_usd)}</p> */}
                            <p className="text-gray-500 text-sm">({formatinusd(item.floor_price_usd)})</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    return mapping;
}

const EthValue = ({ value }) => {
    const [usd, setUsd] = useState(0);

    async function init() {
        let result = await usdToEth(value);
        setUsd(result);
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <p className="text-lg font-semibold">{usd} ETH</p>
    )
}


const GetErc20Labels = ({ type }) => {

    return (
        <div className="flex items-center gap-x-4 my-10 hover:bg-gray-100 px-0 border-b-2 border-gray-200 pb-4">
            <div className="w-[30px] lg:w-[60px]">
                <p className="text-left text-xl font-bold text-gray-500">{""}</p>
            </div>
            <div className="w-11/12 flex items-center gap-x-8 ">
                <p className="text-lg font-semibold text-gray-500">{type == "erc20" ? "Token" : "Collection"}</p>
                <div className="flex items-center justify-between w-full">
                    <div className="w-6/12">
                        <p className="text-black text-lg font-bold flex truncate">{ }</p>
                        <p className="text-gray-500 text-sm">{ }</p>
                    </div>

                    <div className="w-6/12 flex items-center justify-between">
                        <p className="text-lg font-semibold text-gray-500">{"Market Cap"}</p>

                        <div className="flex flex-col items-end">
                            <p className="text-lg font-semibold text-gray-500">{type == "erc20" ? "Price" : "Floor Price"}</p>

                        </div>
                    </div>

                </div>
            </div>
        </div >
    )

}


const GetErc20Mapping = ({ array, skip }) => {

    const mapping = array.map((item, index) =>
        <a key={index} className="flex items-center gap-x-4 my-10 hover:bg-gray-100 px-0" href={"/eth/token/" + item.contract_address}>
            <div className="w-[30px] lg:w-[60px]">
                <p className="text-left text-xl font-bold text-gray-500">{skip + index}</p>
            </div>
            <div className="w-11/12 flex items-center gap-x-8 ">
                <div className="w-10 h-10 rounded-3xl">
                    <img src={item.token_logo} className="rounded-lg" />
                </div>
                <div className="flex items-center justify-between w-full">
                    <div className="w-4/12 md:w-6/12">
                        <p className="text-black text-lg font-bold flex truncate">{item.token_name}</p>
                        <p className="text-gray-500 text-sm">{item.token_symbol}</p>
                    </div>

                    <div className="w-8/12 md:w-6/12 flex items-center justify-between">
                        <p className="text-lg font-semibold">{formatinusd(item.market_cap_usd)}</p>

                        <div className="flex flex-col items-end">
                            <p className="text-lg font-semibold">{formatinusd(item.price_usd)}</p>
                            <div className="flex items-center gap-x-2">
                                <FontAwesomeIcon icon={["fas", parseFloat(item.price_24h_percent_change) > 0 ? "fa-caret-up" : "fa-caret-down"]} className={parseFloat(item.price_24h_percent_change) > 0 ? "text-green-500" : "text-red-500"} />
                                <p className={parseFloat(item.price_24h_percent_change) > 0 ? "text-green-500 text-sm" : "text-red-500 text-sm"}>{item.price_24h_percent_change}%</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </a>
    );
    return mapping;
}
