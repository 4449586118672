import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Titles from "./partials/title.js";
import { colors } from "../../../../1.resources/1.css/colors";
import { CgDetailsMore } from "react-icons/cg";
import { BiDetail } from "react-icons/bi";
import Glance from "./partials/glance.js";

const Upper = ({ tabSelected, setTabSelected, totalErc20, txDetails, copyp, setCopyp, copyClipboard }) => {
    const [currentAddress, setCurrentAddress] = useState("");

    useEffect(() => {
        setCurrentAddress(window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1));
    }, [])


    return (

        <div className="bg-gray-100 flex justify-center pt-6 md:pt-8 bg-white border-b-2 border-gray-200">
            <div className="w-full lg:w-[1400px] px-5 md:px-10 lg:px-20">
                <Titles currentAddress={currentAddress} txDetails={txDetails} copyp={copyp} setCopyp={setCopyp} copyClipboard={copyClipboard} />
                <Glance currentAddress={currentAddress} txDetails={txDetails} />

                <div className="flex items-center gap-x-10 mt-10">
                    <button onClick={() => setTabSelected("details")} className={`font-semibold flex items-center gap-x-3 pb-3 ${tabSelected == "details" ? ("border-b-2 border-b-main") : ("text-gray-500")}`}>
                        <CgDetailsMore className="text-xl" style={{ color: tabSelected == "details" ? colors.main : colors.gray500 }} />
                        <p className={`text-md ${tabSelected == "details" ? "text-main" : "text-gray-500"}`}>Details</p>
                    </button>
                </div >
            </div>
        </div >
    );

}

export default Upper;
