import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let links = {
    "Tokens": [
        { "name": "Top Tokens", "link": "/top/erc20", "icon": "" },
        { "name": "Top Gainers", "link": "/top/er20/gainers", "icon": "" },
    ],
    "NFTs": [
        { "name": "Top NFTs", "link": "/top/erc721", "icon": "" },
        { "name": "Top Gainers", "link": "/top/erc721/gainers", "icon": "" }
    ],
}
const HeaderLinks = ({ }) => {

    return (
        <div className="flex gap-x-6 justify-center items-center w-full">
            {/* <a href="/" className="cursor-pointer font-bold text-md">Home</a> */}

            <MenuItem name="Tokens" items={links.Tokens} width="300%" left="-100%" />
            <MenuItem name="NFTs" items={links.NFTs} width="400%" left="-300%" />
            {/* <MenuItem name="Account" items={links.Account} width="250%" left="-70%" />
            <a href="https://docs.wnsdomains.io" className="cursor-pointer font-semibold text-md">Docs</a> */}
        </div>

    );

}


export default HeaderLinks;


export const MenuItem = ({ name, items, width, left }) => {

    return (
        <div className="dropdown" >
            <a className="cursor-pointer font-semibold text-md">{name}</a>

            <div id="dropdown-content" className="dropdown-content shadowDiv" style={{ width: width, left: left }}>

                <p className="text-gray-500 px-4 py-2 text-sm font-semibold border-b border-b-gray-200">Explore {name}</p>
                {items.map((item, index) => (
                    <a key={index} href={item.link}>
                        <div className="px-4 py-3 flex items-center">
                            {item.icon != "" ?
                                (
                                    <div style={{ width: "15%" }}>
                                        <FontAwesomeIcon icon={['fas', item.icon]} style={{ fontSize: "100%" }} />
                                    </div>
                                ) : (
                                    <div style={{ width: "0%" }}>
                                    </div>
                                )}

                            <div style={{ width: "85%" }}>
                                <p className="font-semibold text-md">{item.name}</p>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    )
}