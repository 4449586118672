import Main from "./main";
import "./1.resources/2.js/0.global/0.smallfunctions/imports_css";

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
