import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { shortenaddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { getWnsDomain } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getChainLogo } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { Tooltip } from "@material-tailwind/react";

const Titles = ({ currentAddress, txDetails, copyp, setCopyp, copyClipboard }) => {


    return (
        <div className="">
            {/* <p className="font-semibold text-xl">Transaction Details</p> */}

            <div className="pb-8 md:flex items-center justify-between ">
                <div className="flex items-center gap-x-8">
                    <div>
                        <p className="text-3xl font-bold" style={{}}>{"Transaction"}</p>
                        <div className="mt-3 flex items-center gap-x-2">
                            <p className="text-gray-500 text-md">Hash: </p>
                            <img src={getChainLogo()} className="w-5 h-5 rounded-full" />
                            <Tooltip content={copyp} placement="bottom" className="bg-gray-500 text-xs py-1 px-4">
                                <div className="flex items-center gap-x-2" onClick={() => copyClipboard(currentAddress)}>
                                    <p className="text-gray-500 text-md">{shortenaddress(currentAddress)}</p>
                                    <div>
                                        <FontAwesomeIcon icon={['far', 'fa-copy']} className="text-gray-500 text-xs" />
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                <div>
                    {txDetails != null ? (
                        txDetails.block_number == null ? (
                            <div className="bg-amber-100 rounded-lg p-2 px-4 w-fit h-fit flex items-center mt-5 md:mt-0">
                                <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-amber-500 text-sm" spin />
                                <p className="text-amber-500 pl-1 text-sm">Pending</p>
                            </div>
                        ) : (txDetails.receipt_status != "0" ? (
                            <div className="bg-green-100 border border-green-200 rounded-lg p-2 px-4 w-fit h-fit flex items-center mt-5 md:mt-0">
                                <div className="bg-green-500 w-4 h-4 rounded-full flex justify-center items-center">
                                    <FontAwesomeIcon icon={['fas', 'fa-check']} className="text-green-100 text-xs" />
                                </div>
                                <p className="text-green-500 pl-1 text-sm">Success</p>
                            </div>
                        ) : (
                            <div className="bg-red-100 rounded-lg p-2 px-4 w-fit h-fit flex items-center mt-5 md:mt-0">
                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} className="text-red-500 text-xs" />
                                <p className="text-red-500 pl-2 text-sm">Failed</p>
                            </div>
                        )

                        )
                    ) : (<div />)}
                </div>
            </div>
        </div >
    );

}

export default Titles;
