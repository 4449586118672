import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import EmptyImg from "../../../../1.resources/3.files/images/empty.png";
import Transactions from "./partials/transactions/transactions";
import Holders from "./partials/holders/holders";

const Lower = ({ tabSelected, setTabSelected, setTotalErc20, setTotalNfts, valid }) => {
    const [currentAddress, setCurrentAddress] = useState("");

    useEffect(() => {
        setCurrentAddress(window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1));
    }, [])


    return (

        <div className="bg-gray-100 flex justify-center md:py-10 ">
            <div className="w-full lg:w-[1400px] px-0 md:px-10 lg:px-20">

                {valid ? (
                    <div className="w-full ">
                        <div style={{ display: tabSelected == "transactions" ? "block" : "none" }} className="bg-white md:rounded-2xl border-2 border-gray-200">
                            <Transactions currentAddress={currentAddress} />
                        </div>
                        <div style={{ display: tabSelected == "holders" ? "block" : "none" }} className="bg-white md:rounded-2xl border-2 border-gray-200">
                            <Holders currentAddress={currentAddress} setTotalErc20={setTotalErc20} />
                        </div>
                    </div>
                ) : (
                    <div className=" bg-white rounded-3xl border-2 border-gray-200">
                        <div className="flex justify-center items-center">
                            <div>
                                <img src={EmptyImg} className="w-80" />
                                <div className="bg-white rounded-lg p-2 flex justify-center items-center my-2 pb-10">
                                    <div>
                                        <p className="text-black pl-1 text-2xl font-semibold text-center">Invalid address</p>
                                        <p className="text-gray-500 pl-1 text-lg text-center">This is an invalid account</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div >
    );

}

export default Lower;
