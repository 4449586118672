import React, { useState, useContext, useEffect } from "react";
import TxList from "./partials/txList";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { colors } from "../../../../../../1.resources/1.css/colors";
import { TbCoins } from "react-icons/tb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Transactions = ({ currentAddress }) => {
    const [tabSelected, setTabSelected] = useState("normal");

    return (
        <div>
            <TxList currentAddress={currentAddress} type={tabSelected} />
        </div>
    )

}

export default Transactions;
