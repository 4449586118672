import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { allChains, getChainPath } from "../../../1.resources/2.js/0.global/0.smallfunctions/chain";

class MobileSidebar extends React.Component {

    componentDidMount() {
        document.getElementById("mobileSidebar").style.height = window.innerHeight - document.getElementById("mobileHeader") + "px";
    }
    render() {
        return (
            <MobileSidebarFunc setMobileSidebar={this.props.setMobileSidebar} />
        )
    }
}

const MobileSidebarFunc = ({ setMobileSidebar }) => {

    function setChain(link) {
        window.location = window.location.pathname.replace(getChainPath(), "/" + link);
    }

    return (
        <div id="mobileSidebar" className="bg-white pt-4 px-5 ">
            <p className="block truncate font-semibold text-2xl pb-4">Select network</p>
            <div className="overflow-y-scroll">
                {allChains().map((chain, index) => (
                    <div key={index} className="flex items-center gap-x-4 border-b-2 border-b-gray-200 py-4" onClick={() => setChain(chain.link)}>
                        <img src={chain.img} className="w-6 h-6 rounded-full" />
                        <span className={`block truncate font-semibold text-lg`}>{chain.name}</span>
                    </div>

                ))}
            </div>
        </div >
    )

}

export default MobileSidebar;
