import React, { useState, useContext, useEffect } from "react";
import HeaderSearch from "../0.global/header/headerSearch";
import Logo from "../../1.resources/3.files/logo/logo2_white.png";
import HeroImg from "../../1.resources/3.files/images/herogradient2.png";
import { allChains, getChain, getChainLogo, getChainName, getChainSymbol } from "../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatinusd, usdToEth } from "../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { callMoralisApi, callW3Api } from "../../1.resources/2.js/0.global/3.api/callW3Api";

const Home = ({ }) => {

    useEffect(() => {
        document.getElementById("title").innerHTML = "W3 Explorer - Explore, monitor and stay secure"
    }, [])

    let colors = [
        'bg-green-700',
        'bg-blue-700',
        // 'bg-red-700',
        'bg-yellow-700',
        // 'bg-orange-700',
        'bg-lime-700',
        'bg-emerald-700',
        'bg-teal-700',
        'bg-cyan-700',
        'bg-sky-700',
        // 'bg-rose-700',
        // 'bg-amber-700',
        'bg-stone-700',

    ]

    const chains = [
        { id: "ethereum", name: 'Ethereum', link: "eth", symbol: "ETH", unavailable: false, img: "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/128/Ethereum-ETH-icon.png" },
        { id: "matic-network", name: 'Polygon', link: "polygon", symbol: "MATIC", unavailable: false, img: "https://cdn.iconscout.com/icon/free/png-256/polygon-token-4086724-3379854.png" },
        { id: "binancecoin", name: 'BSC', link: "bsc", symbol: "BNB", unavailable: false, img: "https://seeklogo.com/images/B/binance-smart-chain-bsc-logo-9C34053D61-seeklogo.com.png" },
        { id: "avalanche-2", name: 'Avalanche', link: "avalanche", symbol: "AVAX", unavailable: false, img: "https://upload.wikimedia.org/wikipedia/en/0/03/Avalanche_logo_without_text.png" },
        { id: "fantom", name: 'Fantom', link: "fantom", symbol: "FTM", unavailable: false, img: "https://etherscan.io/token/images/fantomtoken_128.png" },
        { id: "crypto-com-chain", name: 'Cronos', link: "cronos", symbol: "CRO", unavailable: false, img: "https://cryptopotato.com/wp-content/uploads/2019/09/Crypto.com-coin-min.jpg" },
        // { id: "ethereum", name: 'Arbitrum', link: "arbitrum", symbol: "ETH", unavailable: false, img: "https://pbs.twimg.com/profile_images/1490751860461953029/046qIxwT_400x400.jpg" }
    ]

    return (
        <div>
            <div className="w-full min-h-screen" >
                <div className=" px-0 pt-0 flex justify-center" style={{ backgroundImage: `url()`, backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed" }}>
                    <div className="w-full flex justify-center py-4 bg-gray-100">
                        <div className="w-full lg:w-[1400px] px-5 md:px-10 lg:px-20 py-0">
                            <div className="hidden md:block text-black">
                                <p className="text-8xl md:text-8xl font-bold text-start mt-4 whitespace-nowrap  md:-ml-[460px] text-[#627EEA] uppercase"><span className="text-gray-300">Explore. </span>Explore. <span className="text-gray-300">Explore. Explore. Explore.</span></p>
                                <p className="text-8xl md:text-8xl font-bold text-start whitespace-nowrap  md:-ml-[480px] text-[#627EEA] mt-0 uppercase"><span className="text-gray-300">Monitor. </span>Monitor. <span className="text-gray-300">Monitor. Monitor. Monitor.</span></p>
                                <p className="text-8xl md:text-8xl font-bold text-start pb-4 whitespace-nowrap  md:-ml-[660px] text-[#627EEA] mt-0 uppercase"><span className="text-gray-300">Stay secure. </span>Stay secure. <span className="text-gray-300">Stay secure. Stay secure.</span></p>
                            </div>
                            <div className="block md:hidden text-black pt-4">
                                {/* <div className="flex items-center gap-x-4">
                                    <FontAwesomeIcon icon={"magnifying-glass"} className="text-[#627EEA] text-7xl" />
                                    <FontAwesomeIcon icon={"magnifying-glass"} className="text-gray-300 text-7xl" />
                                    <FontAwesomeIcon icon={"magnifying-glass"} className="text-gray-300 text-7xl" />
                                    <FontAwesomeIcon icon={"magnifying-glass"} className="text-gray-300 text-7xl" />
                                    <FontAwesomeIcon icon={"magnifying-glass"} className="text-gray-300 text-7xl" />
                                    <FontAwesomeIcon icon={"magnifying-glass"} className="text-gray-300 text-7xl" />
                                </div> */}
                                <p className="text-7xl md:text-8xl font-bold text-start pb-4 pt-0 md:-ml-[660px] text-[#627EEA] mt-0 uppercase">Explore</p>
                                <p className="text-7xl md:text-8xl font-bold text-start pb-4 pt-0 md:-ml-[660px] text-[#627EEA] mt-0 uppercase">AND</p>

                                <p className="text-7xl md:text-8xl font-bold text-start pb-4 pt-0 md:-ml-[660px] text-[#627EEA] mt-0 uppercase">MONITOR</p>
                                <div className="flex items-center gap-x-2 pb-4">
                                    <p className="text-xl md:text-8xl text-start  pt-0 md:-ml-[660px] text-[#627EEA] ">with W3 Explorer</p>
                                    <FontAwesomeIcon icon={"circle-check"} className="text-[#627EEA] text-xl" />
                                </div>


                            </div>
                        </div>
                    </div>

                </div>


                <div>
                    <Main />
                </div>



                {/* <p className="text-9xl font-bold px-20">Stay on top.</p> */}

                {/* <div className="pt-5 md:pt-0">
                    <Mapping array={chains} />
                </div> */}
            </div>
        </div>
    );

}

export default Home;

const Main = ({ chain }) => {

    return (
        <div className="w-full flex justify-center py-4 bg-white">

            <div className="w-full lg:w-[1400px] px-5 md:px-10 lg:px-20 py-5 md:py-10 ">

                <div className="">
                    <div className="flex gap-x-4 items-center justify-start border-b-2 border-gray-200 w-full pb-6 md:pb-8">

                        <div className="flex gap-x-4 items-center ">
                            <img src={getChainLogo()} className="w-10 h-10 md:w-12 md:h-12 rounded-2xl bg-[#627EEA]" />
                            <p className="text-4xl md:text-5xl font-bold uppercase">{getChainName()}</p>
                        </div>
                        <div className="bg-blue-100 px-4 py-2 rounded-2xl w-fit">
                            <p className="text-md text-blue-500">{getChainSymbol()}</p>
                        </div>
                    </div>

                    <div className="mt-10">
                        <Boxes />
                    </div>

                    <div className="mt-10">
                        <TopErc20 />
                    </div>
                </div>
            </div>
        </div>
    )
}

const TopErc20 = ({ chain }) => {
    const [erc20Marketcap, setErc20Marketcap] = useState([]);
    const [erc721Marketcap, setErc721Marketcap] = useState([]);
    const [erc20Hot, setErc20Hot] = useState([]);
    const [erc721Hot, setErc721Hot] = useState([]);

    async function init() {
        let [erc20MarketcapResult, erc721MarketcapResult, erc20HotResult, erc721HotResult] = await Promise.all([
            callMoralisApi("/market-data/erc20s/top-tokens", {}),
            callMoralisApi("/market-data/nfts/top-collections", {}),
            callMoralisApi("/market-data/erc20s/top-movers", {}),
            callMoralisApi("/market-data/nfts/hottest-collections", {}),
        ]);

        console.log(erc20MarketcapResult);
        console.log(erc721MarketcapResult);
        console.log(erc20HotResult);
        erc20HotResult = erc20HotResult.gainers;
        //remove duplicates
        let erc20Filtered = erc20MarketcapResult.filter((item, index) => { return erc20MarketcapResult.findIndex(obj => obj.contract_address === item.contract_address) === index; })
        let erc20HotFiltered = erc20HotResult.filter((item, index) => { return erc20HotResult.findIndex(obj => obj.contract_address === item.contract_address) === index; })

        console.log(erc20Filtered);
        console.log(erc721MarketcapResult);

        setErc20Marketcap(erc20Filtered);
        setErc20Hot(erc20HotFiltered);
        setErc721Marketcap(erc721MarketcapResult);
        setErc721Hot(erc721HotResult);

    }

    useEffect(() => {
        init();
    }, [])

    return (
        <div>
            <div className="md:bg-gray-100 md:px-14 pt-10 md:pt-14 pb-4">
                <div className="flex items-center justify-between border-b-2 border-gray-200 pb-8">
                    <div>
                        <p className="text-3xl font-bold uppercase">Top Tokens</p>
                        <p className="text-gray-500 mt-2">Tokens sorted by market cap</p>
                    </div>
                    <a className="flex items-center gap-x-4" href="/top/erc20">
                        <p className="text-blue-500 font-semibold">View all</p>
                        <FontAwesomeIcon icon="arrow-right" className="text-blue-500 text-sm" />
                    </a>
                </div>
                <div className="flex items-center justify-between gap-x-20 mt-2">
                    <div className="w-full md:w-6/12">
                        <Erc20Label type={"erc20"} />
                        <GetErc20Mapping array={erc20Marketcap.slice(0, 5)} skip={1} />
                    </div>
                    <div className="hidden md:block w-6/12">
                        <Erc20Label type={"erc20"} />
                        <GetErc20Mapping array={erc20Marketcap.slice(5, 10)} skip={6} />
                    </div>
                </div>
            </div>

            <div className="md:bg-gray-100 md:px-14 pt-10 md:pt-14 pb-4 md:mt-20">

                <div className="flex items-center justify-between border-b-2 border-gray-200 pb-8">
                    <div>
                        <p className="text-3xl font-bold uppercase">Top Gainers</p>
                        <p className="text-gray-500 mt-2">Tokens sorted by price change</p>
                    </div>
                    <a className="flex items-center gap-x-4" href="/top/erc20/gainers">
                        <p className="text-blue-500 font-semibold">View all</p>
                        <FontAwesomeIcon icon="arrow-right" className="text-blue-500 text-sm" />
                    </a>
                </div>
                <div className="flex items-center justify-between gap-x-20 mt-2">
                    <div className="w-full md:w-6/12">
                        <Erc20Label type={"erc20"} />
                        <GetErc20Mapping array={erc20Hot.slice(0, 5)} skip={1} />
                    </div>
                    <div className="hidden md:block w-6/12">
                        <Erc20Label type={"erc20"} />
                        <GetErc20Mapping array={erc20Hot.slice(5, 10)} skip={6} />
                    </div>
                </div>
            </div>

            <div className="md:bg-gray-100 md:px-14 pt-10 md:pt-14 pb-4 md:mt-20">

                <div className="flex items-center justify-between border-b-2 border-gray-200 pb-8">
                    <div>
                        <p className="text-3xl font-bold uppercase">Top NFTs</p>
                        <p className="text-gray-500 mt-2">NFTs sorted by market cap</p>
                    </div>
                    <a className="flex items-center gap-x-4" href="/top/erc721">
                        <p className="text-blue-500 font-semibold">View all</p>
                        <FontAwesomeIcon icon="arrow-right" className="text-blue-500 text-sm" />
                    </a>
                </div>
                <div className="flex items-center justify-between gap-x-20 mt-2">
                    <div className="w-full md:w-6/12">
                        <Erc20Label type={"erc721"} />
                        <GetErc721Mapping array={erc721Marketcap.slice(0, 5)} skip={1} />
                    </div>
                    <div className="hidden md:block w-6/12">
                        <Erc20Label type={"erc721"} />
                        <GetErc721Mapping array={erc721Marketcap.slice(5, 10)} skip={6} />
                    </div>
                </div>
            </div>

            <div className="md:bg-gray-100 md:px-14 pt-10 md:pt-14 pb-4 md:mt-20">

                <div className="flex items-center justify-between border-b-2 border-gray-200 pb-8">
                    <div>
                        <p className="text-3xl font-bold uppercase">Top NFT Gainers</p>
                        <p className="text-gray-500 mt-2">NFTs sorted by price change</p>
                    </div>
                    <a className="flex items-center gap-x-4" href="/top/erc721/gainers">
                        <p className="text-blue-500 font-semibold">View all</p>
                        <FontAwesomeIcon icon="arrow-right" className="text-blue-500 text-sm" />
                    </a>
                </div>
                <div className="flex items-center justify-between gap-x-20 mt-2">
                    <div className="w-full md:w-6/12">
                        <Erc20Label type={"erc721"} />
                        <GetErc721Mapping array={erc721Hot.slice(0, 5)} skip={1} />
                    </div>
                    <div className="hidden md:block w-6/12">
                        <Erc20Label type={"erc721"} />
                        <GetErc721Mapping array={erc721Hot.slice(5, 10)} skip={6} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Erc20Label = ({ type }) => {

    return (
        <div className="flex items-center gap-x-4 mt-8 hover:bg-gray-100 px-0" >
            <div className="w-[30px]">
                <p className="text-left text-md font-bold text-gray-500">{"#"}</p>
            </div>
            <div className="w-11/12 flex items-center justify-between">
                <p className="text-black text-md font-bold flex truncate text-gray-500">{type == "erc20" ? "Token" : "Collection"}</p>

                <p className="text-black text-md font-bold flex truncate text-gray-500">{type == "erc20" ? "Price" : "Floor Price"}</p>
            </div>
        </div >
    )
}

const GetErc721Mapping = ({ array, skip }) => {

    const mapping = array.map((item, index) =>
        <div key={index} className="flex items-center gap-x-4 my-10 hover:bg-gray-100 px-0" >
            <div className="w-[30px]">
                <p className="text-left text-xl font-bold text-gray-500">{skip + index}</p>
            </div>
            <div className="w-11/12 flex items-center gap-x-4 ">
                <div className="w-10 h-10 md:w-16 md:h-16 rounded-3xl">
                    <img src={item.collection_image} className="rounded-lg" />
                </div>
                <div className="flex items-center justify-between w-full">
                    <div className="">

                        <p className="hidden md:block text-black text-lg font-bold flex truncate">{item.collection_title}</p>
                        <p className="block md:hidden text-black text-lg font-bold flex truncate">{item.collection_title.length > 10 ? item.collection_title.substring(0, 10) + "..." : item.collection_title}</p>

                        <p className="text-gray-500 text-sm">{item.token_symbol}</p>
                    </div>
                    <div className="flex items-center gap-x-8">
                        <div className="flex flex-col items-end">
                            <EthValue value={item.floor_price_usd} />
                            {/* <p className="text-lg font-semibold">{usdToEth(item.floor_price_usd)}</p> */}
                            <p className="text-gray-500 text-sm">({formatinusd(item.floor_price_usd)})</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    return mapping;
}

const EthValue = ({ value }) => {
    const [usd, setUsd] = useState(0);

    async function init() {
        let result = await usdToEth(value);
        setUsd(result);
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <p className="text-lg font-semibold">{usd} ETH</p>
    )
}

const GetErc20Mapping = ({ array, skip }) => {

    const mapping = array.map((item, index) =>
        <a key={index} className="flex items-center gap-x-4 my-10 hover:bg-gray-100 px-0" href={"/eth/token/" + item.contract_address}>
            <div className="w-[30px]">
                <p className="text-left text-xl font-bold text-gray-500">{skip + index}</p>
            </div>
            <div className="w-11/12 flex items-center gap-x-4 ">
                <div className="w-10 h-10 rounded-3xl">
                    <img src={item.token_logo} className="rounded-lg" />
                </div>
                <div className="flex items-center justify-between w-full">
                    <div className="">
                        <p className="text-black text-lg font-bold flex truncate">{item.token_name}</p>
                        <p className="text-gray-500 text-sm">{item.token_symbol}</p>
                    </div>
                    <div className="flex items-center gap-x-8">
                        <div className="flex flex-col items-end">
                            <p className="text-lg font-semibold">{formatinusd(item.price_usd)}</p>
                            <div className="flex items-center gap-x-2">
                                <FontAwesomeIcon icon={["fas", parseFloat(item.price_24h_percent_change) > 0 ? "fa-caret-up" : "fa-caret-down"]} className={parseFloat(item.price_24h_percent_change) > 0 ? "text-green-500" : "text-red-500"} />
                                <p className={parseFloat(item.price_24h_percent_change) > 0 ? "text-green-500 text-sm" : "text-red-500 text-sm"}>{item.price_24h_percent_change}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    );
    return mapping;
}

const Boxes = ({ chain }) => {
    const [details, setDetails] = useState({});

    async function init() {
        let result = await callW3Api("/stats/chain", { chain: getChain().chain });
        console.log(result);
        setDetails(result);
    }
    useEffect(() => {
        init();
    }, [])

    let data = ["Current Price", "Market Cap", "Total Supply", "Circulating Supply"]

    return (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 mt-8 mb-8">
            {data.map((item, index) => (
                <div key={index} className="flex items-center gap-x-4 md:gap-x-4">
                    <div className="w-12 h-12 rounded-full flex justify-center items-center bg-blue-100">
                        {
                            item === "Current Price" ? <FontAwesomeIcon icon="dollar-sign" className="text-blue-500" /> :
                                item === "Market Cap" ? <FontAwesomeIcon icon="chart-line" className="text-blue-500" /> :
                                    item === "Total Supply" ? <FontAwesomeIcon icon="coins" className="text-blue-500" /> :
                                        item === "Circulating Supply" ? <FontAwesomeIcon icon="coins" className="text-blue-500" /> : null
                        }
                    </div>
                    <div>
                        <p className="text-xl font-semibold">{item}</p>
                        <p className="text-lg text-gray-500">{
                            item === "Current Price" ? `${formatinusd(details?.quote?.USD?.price)}` :
                                item === "Market Cap" ? `${formatinusd(details?.quote?.USD?.market_cap)}` :
                                    item === "Total Supply" ? `${details?.total_supply?.toLocaleString()}` :
                                        item === "Circulating Supply" ? `${details?.circulating_supply?.toLocaleString()}` : null
                        }</p>
                    </div>
                </div>
            ))
            }
        </div>
    )
}