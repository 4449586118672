import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { shortenaddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { getWnsDomain } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getChainLogo } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { Tooltip } from "@material-tailwind/react";

const Titles = ({ currentAddress, details, logo }) => {

    const [toolTiptext, setToolTiptext] = useState("address");


    async function copy() {
        await navigator.clipboard.writeText(currentAddress);
        setToolTiptext("Copied!");

        setTimeout(() => {
            setToolTiptext(currentAddress);
        }, 2000);

    }

    useEffect(() => {
        console.log(details);
    }, [])
    return (
        <div className="">

            <p className="font-semibold text-xl">Token Details</p>


            <div className="mt-6 pb-4 md:flex items-center justify-between ">
                <div className="flex items-center gap-x-5">
                    {/* <div className="bg-gray-400 w-16 h-16 flex justify-center items-center rounded-3xl">
                        <FontAwesomeIcon icon="user" className="text-white text-3xl" />
                    </div> */}
                    {details?.logo != null && details?.logo != "" && details?.logo != "null" ? (
                        <div className="w-14 h-14 flex justify-center items-center rounded-2xl bg-white">
                            <img src={details?.logo} className="w-14 h-14 rounded-2xl" />
                        </div>
                    ) : (
                        <div className="w-14 h-14 flex justify-center items-center rounded-2xl bg-gray-400">
                            <FontAwesomeIcon icon="user" className="text-white text-3xl" />
                        </div>
                    )}


                    <div>
                        <div className="flex items-center gap-x-2">
                            <p className="text-2xl font-bold" style={{}}>{details?.name}</p>
                            <p className="text-2xl font-semibold text-gray-500">({details?.symbol})</p>
                        </div>
                        <Tooltip content={toolTiptext} placement="bottom" className="bg-gray-500 py-1 truncate px-4" >
                            <div className="flex items-center gap-x-1 mt-1" onClick={() => copy()}>
                                {/* <img src={getChainLogo()} className="w-4 h-4 rounded-full" /> */}
                                <p className="text-gray-500 text-md ">{shortenaddress(currentAddress ? currentAddress : "")}</p>
                                <FontAwesomeIcon icon="copy" className="text-gray-500 text-xs" />
                            </div>
                        </Tooltip>
                    </div>



                </div>

                <div className="flex flex-col items-end">
                    <a className="hidden md:flex items-center gap-x-2 mt-0 bg-main px-4 py-2 rounded-full w-fit" href={"https://app.uniswap.org/#/tokens/ethereum/" + currentAddress} target={"_blank"}>
                        <p className="text-white text-sm">Uniswap</p>
                        <FontAwesomeIcon icon="arrow-right" className="text-white text-sm" />
                    </a>
                    {/* <a className="hidden md:flex items-center gap-x-2 mt-0 bg-main px-4 py-2 rounded-full w-fit mt-2" href={"https://opensea.io/" + currentAddress} target={"_blank"}>
                        <p className="text-white text-sm">View on Opensea</p>
                        <FontAwesomeIcon icon="arrow-right" className="text-white text-sm" />
                    </a> */}
                </div>
            </div>


        </div >
    );

}

export default Titles;
