import Labels from "../../../3.files/assets/labels.json";
import { callW3Api } from "../3.api/callW3Api";
import { getChain } from "./chain";

export function getLabel(address) {
    if (getChain().chain[0] == "eth") {
        let keys = Object.keys(Labels);

        if (keys.includes(address)) {
            return Labels[keys[keys.indexOf(address)]];
        } else {
            return address;
        }
    } else {
        return address;
    }

}

export async function getNameTag(address) {
    localStorage.removeItem("nametags" + address.toLowerCase());
    let temp = localStorage.getItem("nametags" + address.toLowerCase());
    if (temp != null) {
        return temp;
    } else {
        let result = await callW3Api("/nametag", { address: address, chain: getChain().chain });
        if (result == "null") {
            localStorage.setItem("nametags" + address.toLowerCase(), "null");
            return "null"
        } else {
            localStorage.setItem("nametags" + address.toLowerCase(), result);
            return result;
        }
    }
}