export function allChains() {
    const chains = [
        { id: 1, name: 'Ethereum', link: "eth", unavailable: false, img: "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/128/Ethereum-ETH-icon.png" },
        { id: 2, name: 'Ethereum - Goerli', link: "eth/goerli", unavailable: false, img: "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/128/Ethereum-ETH-icon.png" },
        { id: 3, name: 'Ethereum - Sepolia', link: "eth/sepolia", unavailable: false, img: "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/128/Ethereum-ETH-icon.png" },
        { id: 4, name: 'Polygon', link: "polygon", unavailable: false, img: "https://s3.coinmarketcap.com/static-gravity/image/b8db9a2ac5004c1685a39728cdf4e100.png" },
        { id: 5, name: 'Polygon - Mumbai', link: "polygon/mumbai", unavailable: false, img: "https://s3.coinmarketcap.com/static-gravity/image/b8db9a2ac5004c1685a39728cdf4e100.png" },
        { id: 6, name: 'BSC', link: "bsc", unavailable: false, img: "https://seeklogo.com/images/B/binance-smart-chain-bsc-logo-9C34053D61-seeklogo.com.png" },
        { id: 7, name: 'BSC - Testnet', link: "bsc/testnet", unavailable: false, img: "https://seeklogo.com/images/B/binance-smart-chain-bsc-logo-9C34053D61-seeklogo.com.png" },
        { id: 8, name: 'Avalanche', link: "avalanche", unavailable: false, img: "https://upload.wikimedia.org/wikipedia/en/0/03/Avalanche_logo_without_text.png" },
        { id: 9, name: 'Avalanche - Testnet', link: "avalanche/testnet", unavailable: false, img: "https://upload.wikimedia.org/wikipedia/en/0/03/Avalanche_logo_without_text.png" },
        { id: 10, name: 'Fantom', link: "fantom", unavailable: false, img: "https://etherscan.io/token/images/fantomtoken_128.png" },
        { id: 11, name: 'Cronos', link: "cronos", unavailable: false, img: "https://cryptopotato.com/wp-content/uploads/2019/09/Crypto.com-coin-min.jpg" },
        { id: 12, name: 'Cronos - Testnet', link: "cronos/testnet", unavailable: false, img: "https://cryptopotato.com/wp-content/uploads/2019/09/Crypto.com-coin-min.jpg" },
        { id: 13, name: 'Arbitrum', link: "arbitrum", unavailable: false, img: "https://pbs.twimg.com/profile_images/1490751860461953029/046qIxwT_400x400.jpg" }
    ]
    return chains;
}

export function getChain() {
    let url = window.location.pathname;
    let array = url.split("/");

    let chain;
    let chainId;
    if (array[1] == "eth") {
        if (array[2] == "goerli") {
            chain = ["eth", "goerli"];
            chainId = 2;
        } else if (array[2] == "sepolia") {
            chain = ["eth", "sepolia"];
            chainId = 3;
        } else {
            chain = ["eth", ""];
            chainId = 1;
        }
    } else if (array[1] == "polygon") {
        if (array[2] == "mumbai") {
            chain = ["polygon", "mumbai"];
            chainId = 5;
        } else {
            chain = ["polygon", ""];
            chainId = 4;
        }
    } else if (array[1] == "bsc") {
        if (array[2] == "testnet") {
            chain = ["bsc", "testnet"];
            chainId = 7;
        } else {
            chain = ["bsc", ""];
            chainId = 6;
        }
    } else if (array[1] == "avalanche") {
        if (array[2] == "testnet") {
            chain = ["avalanche", "testnet"];
            chainId = 9;
        } else {
            chain = ["avalanche", ""];
            chainId = 8;
        }
    } else if (array[1] == "fantom") {
        chain = ["fantom", ""];
        chainId = 10;
    } else if (array[1] == "cronos") {
        if (array[2] == "testnet") {
            chain = ["cronos", "testnet"];
            chainId = 12;
        } else {
            chain = ["cronos", ""];
            chainId = 11;
        }
    } else if (array[1] == "arbitrum") {
        chain = ["arbitrum", ""];
        chainId = 13;
    } else {
        chain = ["eth", ""];
        chainId = 1;
    }

    return { chain: chain, chainId: chainId };
}

export function getChainPath() {
    let chain = getChain().chain;

    let chainPath;
    if (chain[1] == "") {
        chainPath = "/" + chain[0];
    } else {
        chainPath = "/" + chain[0] + "/" + chain[1];
    }
    return chainPath;
}

export function getChainSymbol() {
    let chain = getChain().chain;

    if (chain[0] == "eth") {
        return "ETH";
    } else if (chain[0] == "polygon") {
        return "MATIC";
    } else if (chain[0] == "bsc") {
        return "BNB";
    } else if (chain[0] == "avalanche") {
        return "AVAX";
    } else if (chain[0] == "fantom") {
        return "FTM";
    } else if (chain[0] == "cronos") {
        return "CRO";
    } else if (chain[0] == "arbitrum") {
        return "ETH";
    } else {
        return "ETH";
    }
}

export function getChainName() {
    let chain = getChain().chain;

    if (chain[0] == "eth") {
        if (chain[1] == "goerli") {
            return "Ethereum - Goerli";
        } else if (chain[1] == "sepolia") {
            return "Ethereum - Sepolia";
        } else {
            return "Ethereum";
        }
    } else if (chain[0] == "polygon") {
        if (chain[1] == "mumbai") {
            return "Polygon - Mumbai";
        } else {
            return "Polygon";
        }
    } else if (chain[0] == "bsc") {
        if (chain[1] == "testnet") {
            return "BSC - Testnet";
        } else {
            return "BSC";
        }
    } else if (chain[0] == "avalanche") {
        if (chain[1] == "testnet") {
            return "Avalanche - Testnet";
        } else {
            return "Avalanche";
        }
    } else if (chain[0] == "fantom") {
        return "Fantom";
    } else if (chain[0] == "cronos") {
        if (chain[1] == "testnet") {
            return "Cronos - Testnet";
        } else {
            return "Cronos";
        }
    } else if (chain[0] == "arbitrum") {
        return "Arbitrum";
    } else {
        return "Ethereum";
    }
}

export function getChainLogo() {
    return allChains()[getChain().chainId - 1].img;
}