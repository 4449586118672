import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { shortenaddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { getWnsDomain } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { getChainSymbol } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/chain";
import { GetDomain } from "../../../../3.address/components/lower/partials/transactions/partials/txList";


const Glance = ({ txDetails }) => {

    let css = {
        req: "font-semibold text-lg",
        res: "text-sm text-gray-500 mt-1",
        div: ""
    }
    return (
        <div className="">
            {/* <div className="flex justify-between items-center px-0 py-2 rounded-xl">
                <div className="flex items-center gap-x-4">
                    <div className="bg-gray-400 w-12 h-12 flex justify-center items-center rounded-2xl">
                        <FontAwesomeIcon icon="user" className="text-white text-xl" />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-main" style={{}}>{"adam.web3"}</p>
                        <p className="text-gray-500 text-md">{shortenaddress(txDetails.from_address)}</p>
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon icon="arrow-right" className="text-gray-500 text-xl" />
                </div>
                <div className="flex items-center gap-x-4">
                    <div className="bg-gray-400 w-12 h-12 flex justify-center items-center rounded-2xl">
                        <FontAwesomeIcon icon="user" className="text-white text-xl" />
                    </div>
                    <div>
                        <p className="text-lg font-semibold text-main" style={{}}>{"george.web3"}</p>
                        <p className="text-gray-500 text-md">{shortenaddress(txDetails.from_address)}</p>
                    </div>
                </div>

            </div> */}

            {txDetails != null && txDetails != "" && txDetails != "null" ? (
                <div className="mt-0 mb-8">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-6 rounded-2xl text-start ">
                        <div >
                            {/* <a className="font-semibold text-xl text-main" >{shortenaddress(txDetails?.from_address)}</a> */}
                            <p className={css.res}  >From</p>
                            <GetDomain param={"from"} item={txDetails} currentAddress={""} size={window.innerWidth < 767 ? "lg" : "lg"} weight={"semibold"} />

                        </div>
                        <div >
                            {/* <a className="font-semibold text-xl text-main" >{shortenaddress(txDetails?.to_address)}</a> */}
                            <p className={css.res}>To</p>
                            <GetDomain param={"to"} item={txDetails} currentAddress={""} size={window.innerWidth < 767 ? "lg" : "lg"} weight={"semibold"} />

                        </div>
                        <div >
                            <p className={css.res}>Value</p>
                            <div style={{ display: "flex" }} className="flex items-center">
                                <p className={css.req} >{(txDetails?.value / 1e18).toFixed(3)} {getChainSymbol()}</p>
                            </div>
                        </div>
                        <div >
                            <p className={css.res}>Gas fees</p>
                            <div style={{ display: "flex" }} className="flex items-center">
                                <p className={css.req} >{txDetails?.receipt_gas_used != null ? (txDetails?.receipt_gas_used * txDetails?.gas_price / 1e18).toFixed(5) : "-"} {getChainSymbol()}</p>
                            </div>
                        </div>

                    </div>
                </div>
            ) : (null)}
        </div >
    );

}

export default Glance;
